.dashboard{
	width: 100%;
	a:not(.btn-c){
		@include reset-a;
	}
	.btn-c{
		color: $white;
		background: $text4;
		border: solid 1px $text4;
		&:not(.disabled){
			&:hover{
				color: $text4;
				background-color: transparent;
				border: solid 1px $text4;
			}
		}
		&.prev{
			color: $text4;
			background: $white;
			border: solid 1px $text4;
			&:hover{
				color: $white;
				background-color: $text4;
				border: solid 1px $text4;
			}
		}
	}
	.h1,h1,.h2,h2,.h3,h3,.h4,h4,.h5,h5,.h6,h6,p {
		color: $text3;
	}
	.content{
		@include min-md{
			padding-left: 69px + 245px;
		}
		@include max-md{
			background-image: linear-gradient($grey17,$grey);
			padding: 50px 20px;
			margin-left: 69px;
			min-height: 100vh;
		}
		@include max-xs{
			margin-left: 50px;
		}
	}
	.left{
		@include min-md{
			width: 100%;
			position: fixed;
			left: 69px;
			top: 0;
			width: 245px;
			height: 100%;
			display: flex;
			flex-flow: column wrap;
			justify-content: space-between;
			align-items: center;
			padding: 38px 15px 40px;
			background-image: linear-gradient($grey17,$grey); 
		}
		ul{
			width: 155px;
			max-width: 100%;
			@include reset-list;
			@include max-md{
				display: none;
			}
			li{
				&.active{
					a{
						color: $text2;
						.iconfont{
							color: $black;
						}
					}
				}
				+li{
					margin-top: (33rem/18);
				}
				a{
					font-size: 15px;
					font-weight: 500;
					display: flex;
					align-items: center;
					color: $grey18;
					.iconfont{
						margin-right: 12px;
						font-size: 24px;
						transition: all 0.2s;
					}
					&:hover{
						color: $highlight !important;
						.iconfont{
							color: $highlight !important;
							transition: all 0.2s;
						}
					}
				}
			}
		}
		.top{
			.person{
				margin-bottom: 62px;
				img{
					margin: 0 auto;
				}
				p{
					font-size: 16px;
					font-weight: 400;
					margin-bottom: 0;
					color: $text3;
					@include max-md{
						font-size: 14px;
					}
				}
				h2{
					font-size: (24rem/18);
					font-weight: 800;
					color: $text3;
				}
			}
			+ul li a{
				color: rgba($grey18,0.6);
				.iconfont{
					color: $grey29;
				}
			}
		}
	}
	.right{
		@include min-md{
			width: 100%;
			min-height: 100vh;
			padding: 80px 0;
		}
		.right-content{
			padding: 0 80px;
			overflow: auto;
			@include max-md{
				padding: 0;
			}
			.item-c{
				margin-bottom: (48rem/18);
				color: $text3;
				h3{
					margin-bottom: 15px;
				}
			}
			.btn-c{
				padding: 3.5px 18px;
				margin-top: 22px;
				background-color: $text4;
				&:hover{
					color: $text4;
					background-color: transparent;
					border: solid 1px $text4;
				}
				.iconfont{
					margin-right: 15px;
				}
			}
		}
	}
	
	
}