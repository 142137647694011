.tabs-c {
	.content{
		padding: 72px 0 0;
	}
	.tabs.is-boxed {
		list-style: none;
		margin: 0;
		padding: 0;
		ul {
			padding: 0;
			margin: 0;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: center;
			border-bottom: solid 1px rgba($text,0.1);
			li {
				padding: 0;
				margin: 0;
				margin-right: 77px;
				@media (max-width:991.98px){
					margin-right: 37px;
				}
				&:last-child{
					margin-right: 0;
				}
				&:before {
					display: none;
				}
				a {
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: 1.25px;
					color: rgba($text,0.4);
					border-bottom: solid 1px transparent;
					border-radius: 0;
					padding: 24px 0;
					position: relative;
					transform: translate(0,1px);
					@media (max-width:991.98px){
						font-size: 15px;
						line-height: (22/15);
						letter-spacing: 1px;
					}
					&:after {
						content: '';
						display: block;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 8px 7.5px;
						border-color: transparent transparent transparent transparent;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform:translate(-50%,0);
						transition: all 0.2s;
					}
				}
				&:hover {
					a {
						color: $primary;
						border-color: $text;
						&:after {
							border-color: transparent transparent $text transparent;
							transition: all 0.2s;
						}
					}
				}
				&.is-active {
					a {
						letter-spacing: 1px;
						color: $primary;
						border-color: $text;
						&:after {
							border-color: transparent transparent $text transparent;
							transition: all 0.2s;
						}
					}
				}
			}
		}
	}
	.tabs-content {
		border: none;
		>.text-c {
			display: none;
			padding: 5.75rem 0 0;
			flex-flow: row wrap;
			justify-content: space-between;
			&.is-active {
				display: flex;
			}
			.top{
				width: 100%;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				padding-bottom: 72px;
				@media (max-width:767.98px){
					flex-direction: column;
				}
				.text{
					width: calc(100% - 320px - 30px);
					max-width: 620px;
					@media (max-width:767.98px){
						width: 100%;
						max-width: 100%;
						margin-bottom: 2rem;
					}
				}
				.img-c{
					width: 100%;
					max-width: 320px;
					@media (max-width:767.98px){
						width: 100%;
						max-width: 100%;
					}
					div:not([class]){
						padding-top: 309/320*100%;
					}
				}
			}
		}
	}
}