@mixin f-mr {
	font-family: $f-m;
	font-weight: normal;
	font-style: normal;
}


@mixin reset-a {
	border-bottom: none;
	&:hover {
		border-bottom: none;
	}
}

@mixin reset-bg {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@mixin reset-list {
	margin: 0;
	padding: 0;
	li {
		margin: 0;
		padding: 0;
		&:before {
			display: none;
		}
	}
}

@mixin last-0 {
	>* {
		&:first-child {
			margin-top: 0 !important;
		}
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
}

@mixin reset-ab {
	&:before,
	&:after {
		display: none;
	}
}

@mixin grid($gutter) {
	.row {
		margin-right: -$gutter / 2;
		margin-left: -$gutter / 2;
		[class*='col-'] {
			padding-right: $gutter / 2;
			padding-left: $gutter / 2;
		}
	}
}

@mixin from($device) {
	@media screen and (min-width: $device) {
		@content;
	}
}
@mixin until($device) {
	@media screen and (max-width: ($device - 0.02px)) {
		@content;
	}
}
// zl
@mixin min-zl {
	@media screen and (min-width: $zl) {
		@content;
	}
}
@mixin max-zl {
	@media screen and (max-width: ($zl - 0.02px)) {
		@content;
	}
}
@mixin zl-bl {
	@media (max-width: ($zl - 0.02px)) and (min-width: $bl) {
		@content;
	}
}
// bl
@mixin min-bl {
	@media screen and (min-width: $bl) {
		@content;
	}
}
@mixin max-bl {
	@media screen and (max-width: ($bl - 0.02px)) {
		@content;
	}
}
@mixin bl-xl {
	@media (max-width: ($bl - 0.02px)) and (min-width: $xl) {
		@content;
	}
}
// xl
@mixin min-xl {
	@media screen and (min-width: $xl) {
		@content;
	}
}
@mixin max-xl {
	@media screen and (max-width: ($xl - 0.02px)) {
		@content;
	}
}
@mixin xl-lg {
	@media (max-width: ($xl - 0.02px)) and (min-width: $lg) {
		@content;
	}
}
// lg
@mixin min-lg {
	@media screen and (min-width: $lg) {
		@content;
	}
}
@mixin max-lg {
	@media screen and (max-width: ($lg - 0.02px)) {
		@content;
	}
}
@mixin lg-md {
	@media (max-width: ($lg - 0.02px)) and (min-width: $md) {
		@content;
	}
}
// md
@mixin min-md {
	@media screen and (min-width: $md) {
		@content;
	}
}
@mixin max-md {
	@media screen and (max-width: ($md - 0.02px)) {
		@content;
	}
}
@mixin md-sm {
	@media (max-width: ($md - 0.02px)) and (min-width: $sm) {
		@content;
	}
}
// sm
@mixin min-sm {
	@media screen and (min-width: $sm) {
		@content;
	}
}
@mixin max-sm {
	@media screen and (max-width: ($sm - 0.02px)) {
		@content;
	}
}
@mixin sm-xs {
	@media (max-width: ($sm - 0.02px)) and (min-width: $xs) {
		@content;
	}
}
// xs
@mixin min-xs {
	@media screen and (min-width: $xs) {
		@content;
	}
}
@mixin max-xs {
	@media screen and (max-width: ($xs - 0.02px)) {
		@content;
	}
}

@mixin f-18 {
	font-size: 18px;
	line-height: 26px;
}

@mixin mt-default {
	margin-top: 96px;
	@include max-xl{
		margin-top: 5rem;
	}
	@include max-lg{
		margin-top: 4rem;
	}
	@include max-md{
		margin-top: 3rem;
	}
}


@mixin pt-50 {
	padding-top: 50px;
	@include max-xl{
		padding-top: 40px;
	}
	@include max-lg{
		padding-top: 30px;
	}
	@include max-md{
		padding-top: 20px;
	}
}
@mixin pb-50 {
	padding-bottom: 50px;
	@include max-xl{
		padding-bottom: 40px;
	}
	@include max-lg{
		padding-bottom: 30px;
	}
	@include max-md{
		padding-bottom: 20px;
	}
}
@mixin pl-50 {
	padding-left: 50px;
	@include max-xl{
		padding-left: 40px;
	}
	@include max-lg{
		padding-left: 30px;
	}
	@include max-md{
		padding-left: 20px;
	}
}
@mixin pr-50 {
	padding-right: 50px;
	@include max-xl{
		padding-right: 40px;
	}
	@include max-lg{
		padding-right: 30px;
	}
	@include max-md{
		padding-right: 20px;
	}
}

@mixin mt-50 {
	margin-top: 50px;
	@include max-xl{
		margin-top: 40px;
	}
	@include max-lg{
		margin-top: 30px;
	}
	@include max-md{
		margin-top: 20px;
	}
}
@mixin mb-50 {
	margin-bottom: 50px;
	@include max-xl{
		margin-bottom: 40px;
	}
	@include max-lg{
		margin-bottom: 30px;
	}
	@include max-md{
		margin-bottom: 20px;
	}
}
@mixin ml-50 {
	margin-left: 50px;
	@include max-xl{
		margin-left: 40px;
	}
	@include max-lg{
		margin-left: 30px;
	}
	@include max-md{
		margin-left: 20px;
	}
}
@mixin mr-50 {
	margin-right: 50px;
	@include max-xl{
		margin-right: 40px;
	}
	@include max-lg{
		margin-right: 30px;
	}
	@include max-md{
		margin-right: 20px;
	}
}



