* {
	padding: 0;
	margin: 0;
	-webkit-font-smoothing: antialiased; //Mac font
	-moz-osx-font-smoothing: grayscale;
}

img {
	// width: 100%;
	width: auto;
	height: auto;
	display: block;
	max-width: 100%;
}

button {
	outline: none;
	text-decoration: none;
	transition: all 0.2s ease-in-out;
	border: none;
	background-color: transparent;
	&:hover {
		cursor: pointer;
		outline: none;
		text-decoration: none;
		transition: all 0.2s ease-in-out;
	}
	&:focus {
		outline: none;
		text-decoration: none;
		transition: all 0.2s ease-in-out;
	}
	&:visited {
		outline: none;
		text-decoration: none;
		transition: all 0.2s ease-in-out;
	}
}

button,
input:not([type='checkbox']):not([type='radio']),
select,
textarea {
	// -webkit-appearance: none;
	// appearance: none;
}

button,
input[type='checkbox'],
input[type='radio'],
input[type='submit'] {
	transition: all 0.2s ease-in-out;
	cursor: pointer;
}

// select text
.slider {
	touch-action: auto !important;
}

.slick-slider {
	-moz-user-select: text;
	cursor: auto !important;
	-webkit-user-select: auto !important;
	-moz-user-select: auto !important;
}

.img-c,
.bg-box {
	overflow: hidden;
	position: relative;
	z-index: 1;
	// -webkit-mask-image: -webkit-radial-gradient(white,black);
	div {
		width: 100%;
		@include reset-bg;
	}
}

.text-c{
	@include last-0;
}

[class*='wp-']{
	margin: 1.5rem 0;
}

.wp-block-embed .wp-block-embed__wrapper{
	position: relative;
	padding-top: 56.25%;
}

.wp-block-embed .wp-block-embed__wrapper iframe{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.wp-block-gallery{
	ul{
		@include reset-list;
	}
}

.wp-block-quote{
	position: relative;
	padding-left: 30px;
	&:before{
		content: '';
		display: inline-block;
		width: 3px;
		height: 100%;
		background-color: $highlight;
		position: absolute;
		left: 0;
		top: 0;
	}
}


