.login{
	width: 100%;
	height: 100%;
	overflow: hidden;
	@include min-md{
		display: flex;
		flex-flow: row wrap;
		width: 100%;
		min-height: 100vh;
	}
	@include max-md{
		display: flex;
		flex-direction: column-reverse;
	}
	a:not(.btn-c){
		@include reset-a;
	}
	.btn-c{
		color: $white;
		background: $text4;
		border: solid 1px $text4;
		&:not(.disabled){
			&:hover{
				color: $text4;
				background-color: transparent;
				border: solid 1px $text4;
			}
		}
		&.prev{
			color: $text4;
			background: $white;
			border: solid 1px $text4;
			&:hover{
				color: $white;
				background-color: $text4;
				border: solid 1px $text4;
			}
		}
	}
	.left{
		position: relative;
		padding: 0 20px;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		@include min-md{
			width: 650/1440*100%;
		}
		&:before{
			content: '';
			display: inline-block;
			position: absolute;
			width:100%;
			height:100%;
			left: 0;
			top: 0;
			background-image: linear-gradient(-45deg,rgba(216, 210, 237, 1) 0%,rgba(176, 228, 193, 1) 50%,rgba(225, 247, 193, 1) 100%); 
		}
		&:after{
			content: '';
			display: inline-block;
			position: absolute;
			width:100%;
			height:100%;
			left: 0;
			top: 0;
			background-image: linear-gradient(rgba($white,0),rgba($white,1)); 
		}
		.slider{
			position: relative;
			z-index: 1;
			width: 100%;
			max-width: 572px;
			margin: 0 auto;
			padding: 30px 0;
			.slick-next, .slick-prev {
				display: none !important;
			}
			.slick-track,.slick-list{
				display: flex;
				.slick-slide{
					height: 100%;
					>div{
						height: 100%;
						.slick-c{
							height: 100%;
							display: flex !important;
							flex-flow: column nowrap;
							justify-content: flex-end;
							align-items: center;
						}
					}
				}
			}
			.slick-dots{
				display: flex !important;
				flex-flow: row wrap;
				justify-content: center;
				align-items: center;
				@include reset-list;
				margin-top: 30px;
				li{
					padding: 5px;
					cursor: pointer;
					display: flex;
					&.slick-active,&:hover{
						button{
							background-color: $grey7;
							transition: all 0.2s;
						}
					}
					button{
						width: 8px;
						height: 8px;
						border-radius: 50%;
						font-size: 0;
						line-height: 0;
						background-color: $grey6;
						transition: all 0.2s;
					}
				}
			}
			.slick-c{
				.img-c{
					margin-bottom: 30px;
					// @include max-md{
					// 	margin-top: 80px;
					// }
					img{
						width: 100%;
						max-width: 578px;
						margin: 0 auto;
					}
				}
				.text-c{
					width: 100%;
					max-width: 410px;
					margin: 0 auto;
					p:not(.lead-body){
						font-size: 16px;
						line-height: 165%;
						color: rgba($text,0.7);
						margin-bottom: 0;
					}
					p.lead-body{
						font-weight: 900;
						font-size: 18px;
						line-height: 187.5%;
					}
				}
			}
		}
	}
	.right{
		@include min-md{
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			position: relative;
			width: 100% - 650/1440*100%;
		}
		@include max-md{
			margin-top: 80px;
			padding: 0 20px;
		}
		.right-fixed{
			@include min-md{
				position: absolute;
				z-index: 1;
				top: 50px;
				right: 85px;
			}
			@include max-lg{
				right: 35px;
			}
			@include max-md{
				width: 100%;
				max-width: 367px;
				margin: 0 auto;
			}
			p{
				margin-bottom: 0;
				font-size: 14px;
				font-weight: 600;
				color: $text3;
				a{
					color: $text3;
					font-weight: 800;
					margin-left: 12px;
					&:hover{
						color: $highlight;
					}
				}
			}
		}
		.content{
			width: 100%;
			max-width: 367px;
			margin: 0 auto;
		}
		.text-c{
			margin-bottom: 8px;
			max-width: 280px;
			h2{
				font-weight: 900;
				font-size: 24px;
				line-height: 137%;
				margin-bottom: 10px;
			}
			p{
				font-weight: 500;
				font-size: 16px;
				color: $grey13;
				line-height: 1.4;
			}
		}
		.login-social{
			ul{
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-start;
				@include reset-list;
				@include until(376px){
					justify-content: center;
				}
				li{
					opacity: 1;
					transition: all 0.2s;
					margin: 6px 0;
					margin-right: 10px;
					&:hover{
						opacity: 0.7;
						transition: all 0.2s;
					}
					&:last-child{
						margin-right: 0;
					}
					&.google{
						@include until(376px){
							width: 100%;
						}
						a{
							padding: 4px 13px;
							background-color: $blue;
							color: $white;
							span{
								margin: 0 28px 0 48px;
								@include max-xs{
									margin: 0 20px;
								}
							}
						}
					}
					a{
						border-radius: 7px;
						display: flex;
						flex-flow: row wrap;
						justify-content: center;
						align-items: center;
						background-color: $grey8;
						color: $grey9;
						font-weight: 800;
						font-size: 14px;
						min-width: 41px;
						min-height: 41px;
						.iconfont{
							font-size: 20px;
						}
					}
				}
			}
		}
		.or{
			text-align: center;
			position: relative;
			margin-bottom: 6px;
			&:before{
				content: '';
				display: inline-block;
				width: 100%;
				height: 1px;
				background-color: $grey10;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(0,-50%);
			}
			p{
				margin-bottom: 0;
				background-color: $white;
				display: inline-block;
				position: relative;
				z-index: 1;
				font-size: 14px;
				font-weight: 500;
				color: $grey11;
				padding: 0 1rem 1px;
			}
		}
		.copyright{
			width: 100%;
			max-width: 367px;
			margin: 0 auto;
			@include min-md{
				position: absolute;
				left: 50%;
				bottom: 65px;
				transform: translate(-50%,0);
			}
			@include max-md{
				margin-top: 50px;
				margin-bottom: 50px;
			}
			@include max-sm{
				margin-top: 30px;
				margin-bottom: 30px;
			}
			p{
				font-weight: 500;
				font-size: 14px;
				line-height: 1;
				color: $grey2;
				margin-bottom: 0;
				a{
					font-weight: 500;
					font-size: 14px;
					line-height: 1;
					color: $grey2;
					&:hover{
						color: $highlight;
					}
				}
				.dots{
					margin: 0 11px;
				}
			}
		}
	}
}