.step{
	width: 100%;
	height: 100%;
	overflow: hidden;
	@include min-md{
		display: flex;
		flex-flow: row wrap;
		width: 100%;
		min-height: 100vh;
		padding-left: 518/1440*100%;
	}
	@include max-md{
		display: flex;
		flex-direction: column-reverse;
	}
	a:not(.btn-c){
		@include reset-a;
	}
	.btn-c{
		color: $white;
		background: $text4;
		border: solid 1px $text4;
		&:not(.disabled){
			&:hover{
				color: $text4;
				background-color: transparent;
				border: solid 1px $text4;
			}
		}
		&.prev{
			color: $text4;
			background: $white;
			border: solid 1px $text4;
			&:hover{
				color: $white;
				background-color: $text4;
				border: solid 1px $text4;
			}
		}
	}
	.left{
		position: relative;
		@include min-md{
			width: 518/1440*100%;
			position: fixed;
			left: 0;
			top: 0;
			min-height: 100vh;
		}
		&:before{
			content: '';
			display: inline-block;
			position: absolute;
			width:100%;
			height:100%;
			left: 0;
			top: 0;
			background-image: linear-gradient(-45deg,rgba(216, 210, 237, 1) 0%,rgba(176, 228, 193, 1) 50%,rgba(225, 247, 193, 1) 100%); 
		}
		&:after{
			content: '';
			display: inline-block;
			position: absolute;
			width:100%;
			height:100%;
			left: 0;
			top: 0;
			background-image: linear-gradient(rgba($white,0),rgba($white,1)); 
		}
	}
	.left{
		overflow: hidden;
		.bg-box{
			position: absolute;
			left: 0;
			bottom: 0;
			&.width-full{
				width: 100%;
				.img-c{
					width: 100%;
					img{
						width: 100%;
					}
				}
			}
		}
		.content{
			position: relative;
			z-index: 1;
			padding: 80px 20px;
			.logo{
				.img-c{
					width: 85px;
					border-radius: 50%;
					overflow: hidden;
					border: solid 4px $white;
					box-shadow: 0 7px 23px rgba($grey3,0.29);
					margin: 0 auto;
					div:not([class]){
						padding-top: 100%;
					}
				}
			}
			.white-box{
				position: relative;
				&:before{
					content: '';
					display: inline-block;
					width: 19px;
					height: 13px;
					background-image: url("../images/tra.svg");
					position: absolute;
					@include max-lg{
						left: 50%;
						top: 1px;
						transform-origin: top center;
						transform: translate(-50%,0) rotate(180deg) ;
					}
				}
				.text-c{
					width: 100%;
					background-color: $white;
					border-radius: 26px;
					box-shadow: 0 7px 23px rgba($text7,0.2);
					@include max-lg{
						margin: 50px auto 0;
					}
					h3{
						font-size: (20rem/18);
						font-weight: 900;
						margin-bottom: 21px;
					}
					p{
						font-size: 16px;
						font-weight: 400;
						opacity: 0.7;
						line-height: 165%;
						margin-bottom: (25rem/18);
						@include max-md{
							font-size: 14px;
						}
					}
				}
			}
		}
	}
	.right{
		width: 100%;
		padding: 64px 20px;
		@include max-md{
			padding: 80px 20px 50px;
		}
		.content{
			width: 100%;
			height: 100%;
			max-width: 688px;
			margin: 0 auto;
		}
	}
}

.progress-bar{
	width: 100%;
	overflow: hidden;
	margin-bottom: 25px;
	.bar{
		width: 100%;
		height: 20px;
		border-radius: 20px;
		background-color: $grey19;
		overflow: hidden;
		@include max-md{
			height: 16px;
			border-radius: 16px;
		}
		ul{
			li{
				a{
					opacity: 0;
				}
				&:first-child{
					&.show{
						&:before{
						}
					}
				}
				&:last-child{
					&.show{
						&:before{
							right: 0;
							left: auto;
							transform: translate(0,0);
						}
					}
				}
				&.show{
					&:before{
						content: '';
						display: inline-block;
						width: 100vw;
						height: 20px;
						background-color: $highlight5;
						position: absolute;
						left: 50%;
						top: 0;
						transform: translate(-100%,0);
						border-radius: 20px;
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						@include max-md{
							height: 16px;
							border-radius: 16px;
						}
					}
				}
			}
		}
	}
	ul{
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		@include reset-list;
		li{
			font-size: (16rem/18);
			font-weight: 500;
			color: $grey20;
			position: relative;
			padding: 3px 0;
			text-align: center;
			@include max-md{
				font-size: 12px;
				line-height: 1.2;
			}
			a{
				cursor: auto;
				@include reset-a;
				color: $grey20;
				&:hover{
					color: $grey20;
				}
				&:before{
					content: '';
					display: inline-block;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: $white;
					position: absolute;
					top: -10px;
					left: 50%;
					transform: translate(-50%,-50%);
					z-index: 2;
					@include max-md{
						top: -8px;
					}
				}
				&:after{
					content: '';
					display: inline-block;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%,-100%);
					box-shadow: 2px 1px 12px rgba($highlight,0.3);
					z-index: 1;
					background-color: $highlight2;
					opacity: 0;
					@include max-md{
						width: 16px;
						height: 16px;
					}
				}
			}
			&:first-child{
				a{
					&:before{
						left: 7px;
						right: auto;
						transform: translate(0,-50%);
						@include max-md{
							left: 5px;
						}
					}
				}
				&.show{
					a{
						&:after{
							opacity: 1;
							right: auto;
							left: 0;
							transform: translate(0,-100%);
						}
					}
				}
			}
			&:last-child{
				a{
					&:before{
						left: auto;
						right: 7px;
						transform: translate(0,-50%);
						@include max-md{
							right: 5px;
						}
					}
				}
				&.show{
					a{
						&:after{
							opacity: 1;
							left: auto;
							right: 0;
							transform: translate(0,-100%);
						}
					}
				}
			}
			&.active{
				a{
					color: $text6;
					font-weight: 600;
					&:hover{
						color: $text6;
					}
				}
			}
			&.show{
				a{
					color: $text6;
					font-weight: 700;
					&:hover{
						color: $text6;
					}
					&:after{
						opacity: 1;
					}
				}
			}
		}
	}
}

.width-content{
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	// min-height: calc(100% - 85px);
	min-height: 502px;
	&.small{
		max-width: 366px;
		margin: 0 auto;
	}
	&.big{
		max-width: 100%;
		margin: 0 auto;
	}
	.text-c{
		margin-bottom: 30px;
		h2{
			font-size: 24px;
			line-height: 1.2;
			font-weight: 900;
		}
		p,ul li,ol li{
			font-size: 14px;
			font-weight: 500;
			color: $grey13;
			line-height: 1.5;
			line-height: 1.4;
		}
		ul,ol{
			text-align: left;
			li{
				margin-bottom: 20px;
			}
		}
		ul{
			li{
				padding-left: 20px;
				&:before{
					width: 7px;
					height: 7px;
					background-color: $highlight2;
					top: 6px;
				}
			}
		}
	}
	.prev-next{
		text-align: center;
		margin-top: 50px;
		.btn-c{
			min-width: 135px;
			padding: 2.5px 33px;
			margin: 5px 10px;
		}
	}
}

.step-1{
	.left .content .white-box:before{
		left: 50%;
		bottom: 0;
		transform: translate(-50%,100%);
		@include max-lg{
			top: auto;
			left: 50%;
			bottom: 1px;
			transform: translate(-50%,100%);
		}
	}
	.left{
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		.content .white-box{
			margin-bottom: 52px;
			.text-c{
				max-width: 369px;
				padding: 46px 36px;
			}
		}
	}
}

.step-2{
	.left .content .white-box:before{
		@include min-lg{
			left: 0;
			bottom: 40px;
			transform-origin: top left;
			transform: rotate(90deg) translate(50%,0);
		}
	}
	.left{
		.content{
			@include min-lg{
				margin-top: 7%;
				display: flex;
				justify-content: center;
				align-items: flex-end;
			}
			.white-box{
				@include min-lg{
					margin-left: 30px;
					margin-bottom: 15px;
				}
				.text-c{
					max-width: 275px;
					padding: 24px 40px;
					h3{
						line-height: 137%;
					}
				}
			}
		}
	}
	.prev-next{
		text-align: left;
		transform: translate(-10px,0);
		.btn-c{
			
		}
	}
}

.step-3{
	.left .content .white-box:before{
		@include min-lg{
			left: 0;
			top: 40px;
			transform-origin: top left;
			transform: rotate(90deg) translate(-50%,0);
		}
	}
	.left{
		.content{
			@include min-lg{
				display: flex;
				justify-content: center;
				margin-top: 5%;
			}
			.white-box{
				@include min-lg{
					margin-left: 30px;
				}
				.text-c{
					max-width: 275px;
					padding: 32px 34px;
					h3{
						line-height: 137%;
						margin-bottom: 13px;
					}
				}
			}
		}
	}
	.width-content{
		&.small{
			max-width: 366px;
		}
		.grey-box{
			border-radius: 10px;
			background-color: $grey22;
			padding: 30px 38px;
			h3{
				font-size: 17px;
				font-weight: 800;
			}
			.form.style2 .box{
				margin-top: 26px;
				.money-c{
					margin-bottom: 0;
					.label{
						label{
							margin: 0 auto;
						}
					}
					.money-box{
						.input{
							display: flex;
							width: 145px;
							margin: 0 auto;
							position: relative;
							>i{
								font-size: 16px;
								line-height: 187.5%;
								font-weight: 700;
								font-style: normal;
								display: flex;
								flex-flow: row wrap;
								justify-content: center;
								align-items: center;
								background-color: $grey8;
								width: 39px;
								height: calc(100% - 2px);
								border: solid 1px transparent;
								border-right-color: rgba($input2,0.74);
								border-top-left-radius: 7px;
								border-bottom-left-radius: 7px;
								position: absolute;
								left: 1px;
								top: 50%;
								transform: translate(0,-50%);
								z-index: 1;
							}
							input{
								padding: 6px 15px;
								padding-left: 60px;
								font-size: 16px;
								color: $text3;
							}
						}
						.message{
							p{
								font-size: 12px;
								font-weight: 500;
								color: $grey13;
								margin-bottom: 0;
							}
							&.error {
								p {
									color: $red;
								}
							}
						}
						// .help{
						// 	@include max-md{
						// 		display: none;
						// 	}
						// }
					}
				}
			}
			.help{
				@include min-lg{
					position: absolute;
					right: -34px;
					top: 50%;
					transform: translate(0,-50%);
				}
				@include max-lg{
					display: flex;
					flex-flow: row wrap;
					justify-content: center;
					align-items: center;
				}
				&:hover{
					.dark-box{
						opacity: 1;
						visibility: visible;
						transition: all 0.2s;
					}
				}
				.iconfont{
					cursor: pointer;
					font-size: (16rem/18);
					@include max-lg{
						transform: translate(15px,0);
					}
				}
				.dark-box{
					position: absolute;
					left: 50%;
					top: 0;
					transform: translate(-50%,-100%);
					width: 400px;
					opacity: 0;
					visibility: hidden;
					z-index: 5;
					transition: all 0.2s;
					@include max-lg{
						top: 0;
						transform: translate(-50%,-100%);
					}
					@include max-md{
						max-width: calc(100vw - 40px);
					}
					.text-c{
						width: 100%;
						background-color: $text;
						border-radius: 10px;
						text-align: left;
						padding: 20px 20px 24px 20px;
						position: relative;
						margin-bottom: 15px;
						&:before{
							content: '';
							display: inline-block;
							width: 19px;
							height: 13px;
							background-image: url("../images/tra-2.svg");
							position: absolute;
							bottom: 1px;
							left: 50%;
							transform: translate(-50%,100%);
							@include max-lg{
								display: none;
							}
						}
						h3{
							font-size: 16px;
							color: $white;
						}
						p{
							color: rgba($white,0.76);
							font-size: 12px;
							margin-bottom: 16px;
						}
					}
				}
			}
		}
		// >.help{
		// 	@include min-md{
		// 		display: none;
		// 	}
		// 	@include max-md{
		// 		margin-top: 20px;
		// 	}
		// 	.iconfont{
		// 		@include max-md{
		// 			display: none;
		// 		}
		// 	}
		// 	h3{
		// 		text-align: center;
		// 	}
		// }
	}
}

.step-4{
	&.step-4-1{
		.left .content .white-box:before{
			@include min-lg{
				left: 50%;
				bottom: 0;
				transform: translate(-50%, 100%);
			}
			@include max-lg{
				top: auto;
				left: 50%;
				bottom: 1px;
				transform: translate(-50%,100%);
			}
		}
		.left{
			.content{
				padding-left: 30px;
				display: flex;
				flex-flow: column-reverse nowrap;
				align-items: center;
				.logo{
					.img-c{
						margin-left: 0;
					}
				}
				.white-box{
					margin-bottom: 40px;
					@include min-md{
						margin-top: 80px;
					}
					.text-c{
						max-width: 389px;
						padding: 32px 25px 32px 38px;
						h3{
							line-height: 137%;
							margin-bottom: 8px;
						}
					}
				}
			}
		}
	}
	&.step-4-2{
		.left .content .white-box:before{
			@include min-lg{
				left: 50%;
				bottom: 0;
				transform: translate(-50%,100%);
			}
			@include max-lg{
				top: auto;
				left: 50%;
				bottom: 1px;
				transform: translate(-50%,100%);
			}
		}
		.left{
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-end;
			align-items: flex-start;
			.content{
				width: 100%;
				display: flex;
				flex-flow: column-reverse nowrap;
				justify-content: center;
				align-items: center;
				.white-box{
					margin-bottom: 34px;
					.text-c{
						margin: 0 auto;
						max-width: 273px;
						padding: 30px 30px 40px 30px;
						h3{
							line-height: 137%;
							margin-bottom: 8px;
						}
					}
				}
			}
		}
	}
	&.step-4-3{
		.bg-box{
			@include min-lg{
				left: 30%;
				top: 13%;
				bottom: auto;
			}
		}
		.left .content .white-box:before{
			left: 40px+12px;
			top: 0;
			transform-origin: left top;
			transform: rotate(180deg) translate(-50%,0);
		}
		.left{
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-end;
			align-items: flex-start;
			.content{
				@include min-lg{
					padding-left: 44px;
					padding-bottom: 62px;
				}
				.logo{
					.img-c{
						margin-left: 12px;
					}
				}
				.white-box{
					margin-top: 34px;
					.text-c{
						max-width: 389px;
						padding: 32px 25px 32px 38px;
						h3{
							line-height: 137%;
							margin-bottom: 8px;
						}
					}
				}
			}
		}
		.form{
			max-width: 554px;
			margin: 0 auto;
			border-radius: 21px;
			box-shadow: 0 7px 23px rgba($text8,0.1);
			padding: 30px 20px 16px;
			.item-c{
				margin-bottom: 15px;
			}
			.box{
				.radio-c{
					.label{
						label{
							margin: 0 auto;
							font-size: 24px;
							font-weight: 900;
							line-height: 125%;
							text-align: center;
							@include max-sm{
								br{
									display: none;
								}
							}
						}
					}
					.single-radio{
						margin-top: 23px;
						justify-content: center;
						@include max-sm{
							max-width: 294px;
							width: 100%;
							margin: 23px auto 0;
						}
						.choose{
							margin: 0 15px;
							@include max-sm{
								width: 50%;
								margin: 0;
							}
							input[type=radio]:checked{
								+label{
									&:after{
										background-color: $highlight2;
									}
								}
							}
							label{
								font-size: 16px;
								color: $text3;
							}
						}
					}
				}
				.group{
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					max-width: 294px;
					width: 100%;
					margin: 0 auto;
					input::-webkit-input-placeholder {
						/* WebKit browsers */
						color: rgba($grey21,0.28);
					}
					input:-moz-placeholder {
						/* Mozilla Firefox 4 to 18 */
						color: rgba($grey21,0.28);
						opacity: 1;
					}
					input::-moz-placeholder {
						/* Mozilla Firefox 19+ */
						color: rgba($grey21,0.28);
						opacity: 1;
					}
					input:-ms-input-placeholder {
						/* Internet Explorer 10+ */
						color: rgba($grey21,0.28);
					}
					.input-c{
						width: 100%;
						label{
							font-size: 12px;
							font-weight: 500;
							color: $black;
							line-height: 25px;
						}
						.input{
							position: relative;
							.iconfont{
								font-size: 16px;
								color: $grey23;
								position: absolute;
								left: 15px;
								top: 50%;
								transform: translate(0,-50%);
							}
							input{
								padding-left: 45px;
								font-size: 12px;
							}
						}
					}
					.half{
						width: calc(50% - 15px);
						@include max-sm{
							width: calc(50% - 5px);
						}
					}
				}
			}
			.tips{
				border-radius: 10px;
				background-color: rgba($highlight6,0.4);
				color: $highlight4;
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				padding: 16px 20px;
				margin-top: 8px;
				@include max-sm{
					flex-flow: row wrap;
				}
				.iconfont{
					font-size: 32px;
					color: $highlight2;
					margin-right: 18px;
					margin-top: 5px;
					@include max-sm{
						margin: 0 0 10px;
					}
				}
				p{
					font-size: 14px;
					font-weight: 400;
					line-height: 143%;
					margin-bottom: 0;
					color: $highlight4;
				}
			}
		}
		.width-content .prev-next{
			margin-top: 20px;
		}
	}
	.width-content{
		>.text-c{
			margin-top: 38px;
			margin-bottom: 17px;
			h2{
				font-weight: 900;
				font-size: 19px;
			}
		}
		.form{
			.nemesis{
				>p{
					display: inline-block;
					font-size: 15px;
					margin-bottom: 10px;
					color: $black;
					@include max-md{
						line-height: 117%;
					}
				}
				strong{
					font-weight: 800;
				}
				.nemesis-choose{
					display: inline-flex;
					flex-flow: row wrap;
					justify-content: flex-start;
					align-items: center;
					margin-left: 3px;
					p{
						margin-bottom: 0;
						font-size: 15px;
						font-weight: 400;
						color: $black;
					}
					.iconfont{
						margin-left: 10px;
						font-size: 20px;
						cursor: pointer;
						color: inherit;
						transition: all 0.2s;
						&:hover{
							color: $highlight;
							transition: all 0.2s;
						}
					}
				}
			}
		}
	}
}

.step-5{
	.left .content .white-box:before{
		@include min-lg{
			left: 50%;
			top: 0;
			transform-origin: left top;
			transform: rotate(180deg) translate(-50%,0);
		}
	}
	.left{
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		.bg-box{
			@include from(1441px){
				top: 0;
				bottom: auto;
			}
			@include max-md{
				top: 0;
				bottom: auto;
			}
		}
		.content{
			margin-top: 70px;
			.white-box{
				margin-top: 40px;
				.text-c{
					max-width: 389px;
					padding: 32px 25px 32px 38px;
					h3{
						line-height: 137%;
						margin-bottom: 8px;
					}
				}
			}
		}
	}
	.commitment{
		border-radius: 21px;
		background-color: $white;
		box-shadow: 0 7px 23px rgba($text8,0.1);
		padding: 28px 35px;
		@include max-sm{
			padding: 28px 20px;
		}
		.title-box{
			h2{
				font-size: 20px;
				line-height: 23px;
				letter-spacing: 0;
			}
			p{
				font-size: 20px;
				font-weight: 400;
				color: rgba($text3,0.62);
				strong{
					font-weight: 800;
					color: rgba($text3,1);
				}
			}
		}
		.end-weight .group{
			padding: 14px 0;
			.item-c{
				border-radius: 21px;
				width: calc(50% - 10px);
				@include max-md{
					+.item-c{
						margin-top: 0;
					}
				}
				@include max-sm{
					width: 100%;
					+.item-c{
						margin-top: 20px;
					}
				}
				.text-c{
					display: block;
					text-align: center;
					padding: 22px 27px;
					margin-bottom: 6px;
					.box{
						max-width: 100%;
						h3{
							font-family: "rubik", sans-serif;
							font-weight: 700;
							font-size: 18px;
							color: $text;
						}
						p{
							font-size: 16px;
							line-height: 165%;
							margin-bottom: 0;
							color: rgba($text,0.7);
						}
					}
					img{
						max-width: 40px;
						margin: 0 auto 22px;
					}
				}
			}
		}
	}
	.form{
		margin-top: 30px;
		.box{
			>p{
				color: $black;
				margin-bottom: 12px;
			}
		}
		.item-c{
			margin-bottom: 22px;
			margin-top: 0;
		}
	}
	.prev-next{
		margin-top: 30px;
		.btn-c{
			
		}
	}
}





