.home-banner{
	.content{
		@include min-md{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: flex-end;
		}
		@include from(1441px){
			padding-left: 115px;
			align-items: center;
		}
		@include until(1441px){
			padding-left: calc((100% - 1240px)/2 + 50px);
		}
		@include until(1410px){
			padding-left: 50px;
		}
		@include max-lg{
			padding-left: 0;
		}
	}
	.text-c{
		flex: 1;
		padding-right: 60px;
		margin-bottom: 5.5%;
		max-width: 700px;
		@include max-lg{
			padding: 0 35px;
		}
		@include max-md{
			padding: 0 20px;
		}
		h2{
			line-height: 111%;
			margin-bottom: 25px;
			@include min-xl{
				font-size: 55px;
			}
		}
		p{
			margin-bottom: 1.9rem;
		}
		strong{
			font-weight: 800;
		}
		.form .box{
			padding-top: 14px;
			input:not([type="submit"]){
				background-color: $white;
			}
			@include max-xl{
				flex-direction: column;
				input[type="submit"]{
					margin-left: 0;
					margin-top: 10px;
					@include max-sm{
						width: 100%;
					}
				}
			}
		}
	}
	.img-c{
		margin: 0 0 0 auto;
		@include min-md{
			width: 565/1258*100%;
		}
		@include max-md{
			width: 100%;
			max-width: 565px;
		}
		div:not([class]){
			padding-top: 563/565*100%;
		}
	}
}

