footer{
	.content{
		@include min-md{
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 40px;
		}
		@include max-md{
			padding: 20px;
			.brand a img{
				margin: 0 auto 20px;
			}
		}
	}
	ul{
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		@include reset-list;
		@include min-md{
			justify-content: flex-end;
			flex: 1;
		}
		@include max-md{
			column-count: 2;
		}
		li{
			margin-left: 78px;
			@include max-md{
				padding: 0 4px;
				width: 50%;
				margin: 0;
				text-align: center;
			}
			a{
				font-size: 16px;
				font-weight: 600;
				@include reset-a;
			}
		}
	}
}