@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Bold.eot');
    src: local('Mulish Bold'), local('Mulish-Bold'),
        url('../fonts/Mulish-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-Bold.woff2') format('woff2'),
        url('../fonts/Mulish-Bold.woff') format('woff'),
        url('../fonts/Mulish-Bold.ttf') format('truetype'),
        url('../fonts/Mulish-Bold.svg#Mulish-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Black.eot');
    src: local('Mulish Black'), local('Mulish-Black'),
        url('../fonts/Mulish-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-Black.woff2') format('woff2'),
        url('../fonts/Mulish-Black.woff') format('woff'),
        url('../fonts/Mulish-Black.ttf') format('truetype'),
        url('../fonts/Mulish-Black.svg#Mulish-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-ExtraBold.eot');
    src: local('Mulish ExtraBold'), local('Mulish-ExtraBold'),
        url('../fonts/Mulish-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-ExtraBold.woff2') format('woff2'),
        url('../fonts/Mulish-ExtraBold.woff') format('woff'),
        url('../fonts/Mulish-ExtraBold.ttf') format('truetype'),
        url('../fonts/Mulish-ExtraBold.svg#Mulish-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-SemiBold.eot');
    src: local('Mulish SemiBold'), local('Mulish-SemiBold'),
        url('../fonts/Mulish-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-SemiBold.woff2') format('woff2'),
        url('../fonts/Mulish-SemiBold.woff') format('woff'),
        url('../fonts/Mulish-SemiBold.ttf') format('truetype'),
        url('../fonts/Mulish-SemiBold.svg#Mulish-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Medium.eot');
    src: local('Mulish Medium'), local('Mulish-Medium'),
        url('../fonts/Mulish-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-Medium.woff2') format('woff2'),
        url('../fonts/Mulish-Medium.woff') format('woff'),
        url('../fonts/Mulish-Medium.ttf') format('truetype'),
        url('../fonts/Mulish-Medium.svg#Mulish-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Regular.eot');
    src: local('Mulish Regular'), local('Mulish-Regular'),
        url('../fonts/Mulish-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mulish-Regular.woff2') format('woff2'),
        url('../fonts/Mulish-Regular.woff') format('woff'),
        url('../fonts/Mulish-Regular.ttf') format('truetype'),
        url('../fonts/Mulish-Regular.svg#Mulish-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: url('../fonts/Rubik-Bold.eot');
    src: local('Rubik Bold'), local('Rubik-Bold'),
        url('../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rubik-Bold.woff2') format('woff2'),
        url('../fonts/Rubik-Bold.woff') format('woff'),
        url('../fonts/Rubik-Bold.ttf') format('truetype'),
        url('../fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?6nwub');
  src:  url('../fonts/icomoon.eot?6nwub#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?6nwub') format('truetype'),
    url('../fonts/icomoon.woff?6nwub') format('woff'),
    url('../fonts/icomoon.svg?6nwub#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}







.icon-calendar:before {
  content: "\e91e";
}
.icon-card:before {
  content: "\e91f";
}
.icon-lock:before {
  content: "\e920";
}
.icon-info:before {
  content: "\e91b";
}
.icon-security:before {
  content: "\e91c";
}
.icon-web:before {
  content: "\e91d";
}
.icon-multi-check:before {
  content: "\e91a";
}
.icon-no:before {
  content: "\e918";
}
.icon-yes:before {
  content: "\e919";
}
.icon-question:before {
  content: "\e917";
}
.icon-link-1:before {
  content: "\e914";
}
.icon-link-2:before {
  content: "\e915";
}
.icon-link-3:before {
  content: "\e916";
}
.icon-face:before {
  content: "\e910";
}
.icon-link:before {
  content: "\e911";
}
.icon-video:before {
  content: "\e912";
}
.icon-share:before {
  content: "\e913";
}
.icon-close:before {
  content: "\e90f";
}
.icon-camera:before {
  content: "\e90e";
}
.icon-change:before {
  content: "\e90d";
}
.icon-facebook:before {
  content: "\e90a";
}
.icon-google:before {
  content: "\e90b";
}
.icon-twitter:before {
  content: "\e90c";
}
.icon-arrow:before {
  content: "\e904";
}
.icon-arrow-2:before {
  content: "\e909";
}
.icon-icon-1:before {
  content: "\e900";
}
.icon-icon-2:before {
  content: "\e901";
}
.icon-icon-3:before {
  content: "\e902";
}
.icon-icon-4:before {
  content: "\e903";
}
.icon-icon-5:before {
  content: "\e905";
}
.icon-icon-6:before {
  content: "\e906";
}
.icon-icon-7:before {
  content: "\e907";
}
.icon-icon-8:before {
  content: "\e908";
}







