@charset "UTF-8";
//wowo new fadeIn function
html.hidden-c {
  body{
//  overflow-x: hidden;
  }
  .wowo {
    opacity: 0;
    &.animated{
      animation-duration: 0.7s;
      visibility: visible;
      animation-fill-mode: both;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0)
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@keyframes fadeInBigUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0)
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0)
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0, 0)
  }
  to {
    opacity: 1;
    transform: none
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(30%, 0, 0)
  }
  to {
    opacity: 1;
    transform: none
  }
}

.fadeInLeft {
  &.animated {
    animation-name: fadeInLeft;
  }
}

.fadeInRight {
  &.animated {
    animation-name: fadeInRight;
  }
}

.fadeIn {
  &.animated {
    animation-name: fadeIn;
  }
}

.fadeInUp {
  &.animated {
    animation-name: fadeInUp;
  }
}

.fadeInBigUp {
  &.animated {
    animation-name: fadeInBigUp;
  }
}

.fadeInDown {
  &.animated {
    animation-name: fadeInDown;
  }
}

.delay-in-1{
  animation-delay: 0.1s;
}

.delay-in-2{
  animation-delay: 0.2s;
}

.delay-in-3{
  animation-delay: 0.3s;
}

.delay-in-4{
  animation-delay: 0.4s;
}