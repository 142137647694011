blockquote {
	font-family: "rubik";
	font-weight: 700;
	font-style: normal;
	font-size: (31rem/18);
	line-height: 147%;
	@include max-xs{
		font-size: 15px;
	}
}

cite{}