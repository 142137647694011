header.header-black{
	position: fixed;
	z-index: 10;
	width: 100%;
	top: 0;
	left: 0;
	transition: all 0.2s;
	background-color: transparent;
	padding-top: $val;
	@include max-md{
		padding-top: 20px;
	}
	&.smaller{
		background-color: $white;
		box-shadow: 0 3px 6px rgba($text,0.1);
		transition: all 0.2s;
		padding-top: 0;
		.content{
			padding: 20px 0;
			transition: all 0.2s;
		}
	}
	.content{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		padding: $val;
		transition: all 0.2s;
		@include max-md{
			padding: 20px;
		}
	}
}

header.header-black{
	+div{
		margin-top: $val;
		@include max-md{
			margin-top: 20px;
		}
		.content{
			@include max-md{
				padding-top: 80px;
			}
		}
	}
	+.home-banner{
		.content{
			@include min-md{
				padding-top: 108px;
			}
		}
	}
	+.guide{
		.content{
			@include min-md{
				padding-top: 135px;
			}
		}
		&.small-height{
			.content{
				padding-bottom: 45px;
				@include min-md{
					padding-top: 115px;
				}
			}
		}
	}
	+.faq-guide{
		.content{
			@include min-md{
				padding-top: 115px;
			}
		}
	}
}

.brand {
	h1{
		margin-bottom: 0;
	}
	a {
		display: flex;
		@include reset-a;
		img{
			max-width: 102px;
			width: 100%;
		}
		svg{
			max-width: 102px;
			width: 100%;
		}
	}
}

.burger-c {
	visibility: hidden !important;
	display: none;
	position: relative;
	z-index: 20;
	@include max-lg{
		visibility: visible !important;
		display: block;
	}
	.burger{
		width: 26px;
		height: 16px;
		opacity: 1;
		transition: all 0.2s;
	}
	&:hover {
		cursor: pointer;
		transition: all 0.4s;
		background-color: transparent;
		span {
			background-color: $highlight;
			transition: all 0.4s ease-in-out;
		}
	}
	&.open {
		span {
			&:first-child {
				top: 7px;
				transform: rotate(-45deg);
			}
			&:nth-child(2) {
				top: 5px;
				transform: rotate(-45deg);
			}
			&:last-child {
				top: 3px;
				transform: rotate(45deg);
			}
		}
	}
	span {
		width: 28px;
		height: 2px;
		display: block;
		position: relative;
		transition: all 0.4s ease-in-out;
		background-color: $text;
		&:first-child {
			top: 0;
		}
		&:nth-child(2) {
			top: 5px;
		}
		&:last-child {
			top: 10px;
		}
	}
}

// body{
// 	position: relative;
// }
header.header-green{
	position: absolute;
	z-index: 9;
	top: 50px;
	left: 73px;
	@include max-lg{
		left: 35px;
	}
	@include max-md{
		top: 30px;
		left: 20px;
		.brand {
			a {
				svg{
					path{
						fill: $text9;
					}
				}
			}
		}
	}
}

header.header-dashboard{
	width: 69px;
	background-color: $text2;
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	@include max-xs{
		width: 50px;
	}
	.brand a img{
		max-width: 32px;
		margin: 20px auto;
		@include max-xs{
			max-width: 26px;
		}
	}
	.links{
		@include min-md{
			display: none;
		}
		@include max-md{
			height: calc(100% - 73px);
			padding: 50px 0;
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			align-items: center;
		}
		@include max-xs{
			height: calc(100% - 67px);
		}
		ul{
			margin-top: 50px;
			@include reset-list;
			li{
				text-align: center;
				a{
					font-size: 1.4rem;
					color: $white;
					@include reset-a;
					&:hover{
						color: $highlight;
					}
				}
			}
		}
	}
}