@charset "UTF-8";
@import "vendors/_stylesheet.scss";//字体

@import "vendors/all";//插件

@import "common/all";//变量

@import "components/all";//组件

@import "modules/all";//模块

@import "../../node_modules/react-slidedown/lib/slidedown.css";

.onboarding-error-feedback {
    margin-top: 15px;
    color: #fa755a;
    font-size: 14px;
    ul {
        li {
            margin-bottom: 0.2rem
        }
    }
}

//自己的变量要放在最最最上面！！！
