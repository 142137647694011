input {
	outline: none;
	border: none;
	border-radius: 0;
	transition: all 0.2s ease-in-out;
	&:hover {
		transition: all 0.2s ease-in-out;
	}
	&:focus {
		transition: all 0.2s ease-in-out;
	}
}

select,
textarea {
	border-radius: 0;
	outline: none;
	border: none;
	resize: none;
}

select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: url('../images/select.svg') no-repeat 95% center transparent;
	background-size: 5%;
	// @media (min-width: 576px) {
	// background: url('../images/tra.svg') no-repeat 94% center transparent;
	// 	background-size: 3%;
	// }
}

select::-ms-expand {
	display: none;
}

input[type="number"]{
	-moz-appearance: textfield;
	-webkit-appearance: textfield;
	appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		appearance: none;
	}
}
input[type="date"]{
	position: relative;
	-moz-appearance: textfield;
	-webkit-appearance: textfield;
	appearance: textfield;
	box-sizing: border-box;
	&::-webkit-calendar-picker-indicator{
		opacity: 0;
		position: absolute;
		top: 0;
		left: -24px;
		right: 0;
		bottom: 0;
		width: auto;
		height: auto;
		color: transparent;
		box-sizing: border-box;
		background: transparent;
	}
	&::-webkit-datetime-edit-text{
		margin: 0 -1px;
	}
	/* adjust increase/decrease button */
	&::-webkit-inner-spin-button {
		z-index: 1;
	}

	/* adjust clear button */
	&::-webkit-clear-button {
		z-index: 1;
	}
}

input:not([type="submit"]),textarea,select{
	transition: all 0.2s;
	&:focus{
		border: solid 1px $green !important;
		transition: all 0.2s;
	}
}

form{
	.item-c{
		margin-bottom: 10px;
		.label{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 3px;
			label{
				font-weight: 800;
				font-size: 14px;
				margin-bottom: 0;
				color: $black;
			}
			a{
				font-weight: 500;
				font-size: 14px;
				line-height: 1;
				color: $grey13;
				border-bottom: solid 1px $grey13;
				&:hover{
					color: $highlight;
					border-bottom: solid 1px $highlight;
				}
			}
		}
	}
	.two-col{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		.item-c{
			width: calc(50% - 8px);
		}
	}
	.with-bool{
		.bool-box{
			display: flex;
			.input{
				width: 130px;
				margin-right: 22px;
			}
			.bool{
				border-radius: 7px;
				background-color: rgba($grey15,0.2);
				display: flex;
				position: relative;
				&.active{
					span.bg{
						transition: all 0.2s;
					}
					span:not(.bg):first-child{
						color: $grey21;
					}
					span:not(.bg):nth-child(2){
						color: $white;
					}
				}
				span:not(.bg){
					font-size: 14px;
					font-weight: 500;
					display: flex;
					flex-flow: row wrap;
					justify-content: center;
					align-items: center;
					padding: 0 20px;
					color: $grey21;
					background-color: transparent;
					cursor: pointer;
					position: relative;
					z-index: 2;
					&:first-child{
						color: $white;
					}
				}
				span.bg{
					background-color: $text4;
					position: absolute;
					height:100%;
					left: 0;
					top: 50%;
					transform: translate(0,-50%);
					transition: all 0.2s;
					border-radius: 7px;
				}
			}
		}
		&.with-check{
			.message{
				display: flex;
				align-items: center;
				margin-top: 10px;
				margin-bottom: 34px;
				&.yes{
					color: $highlight4;
				}
				&.no{
					color: $red;
				}
				.iconfont{
					margin-right: 12px;
					font-size: 12px;
				}
				p{
					color: inherit;
					font-size: 14px;
					font-weight: 700;
					line-height: 155%;
					margin-bottom: 0;
				}
			}
		}
	}
	.radio-c{
		.single-radio{
			display: flex;
			flex-wrap: wrap;
			margin-top: -8px;
			.choose{
				position: relative;
				&:not(:last-child){
					margin-right: 38px;
				}
				input{
					position: absolute;
					left: 0;
					top: 0;
					opacity: 0;
					pointer-events: none;
				}
				input[type="radio"]:checked{
					+label{
						&:after{
							background-color: $grey3;
							transition: all 0.2s;
						}
					}
				}
				label{
					position: relative;
					padding-left: 28px;
					font-size: 14px;
					margin-bottom: 0;
					cursor: pointer;
					color: $text3;
					&:before{
						content: '';
						display: inline-block;
						width: 16px;
						height: 16px;
						border-radius: 50%;
						border: solid 1px $black;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translate(0,-50%);
					}
					&:after{
						content: '';
						display: inline-block;
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background-color: transparent;
						position: absolute;
						left: 3px;
						top: 50%;
						transform: translate(0,-50%);
					}
				}
			}
		}
	}
	.check-c{
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin-top: 18px;
		position: relative;
		.label{
			margin-bottom: 0;
			width: 100%;
			height: 100%;
			label{
				font-weight: 500;
				font-size: 14px;
				line-height: 1.5;
				color: rgba($text3,0.62);
				cursor: pointer;
				position: relative;
				padding-left: 30px;
				height: 100%;
				&:before{
					content: '';
					display: inline-block;
					width: 15px;
					height: 15px;
					border-radius: 3px;
					border: solid 1px $grey24;
					background-color: transparent;
					color: $white;
					transition: all 0.2s;
					position: absolute;
					left: 0;
					top: 4px;
				}
				&:after{
					content: '\e91a';
					font-family: "icomoon" !important;
					width: 15px;
					height: 15px;
					background-color: transparent;
					color: $white;
					transition: all 0.2s;
					display: flex;
					flex-flow: row wrap;
					justify-content: center;
					align-items: center;
					font-size: 12px;
					transform: scale(0.5);
					z-index: 1;
					position: absolute;
					left: 0;
					top: 3px;
				}
				a{
					// color: $highlight;
					// border-color: $highlight;
					// opacity: 1;
					&:hover{
						color: $highlight;
					}
				}
				a.turn-on-pop{
					color: $highlight;
					border-color: $highlight;
					opacity: 1;
					&:hover{
						opacity: 0.7;
					}
				}
			}
		}
		input[type="checkbox"]:checked{
			+.label label{
				&:before{
					background-color: $text4;
					border: solid 1px $text4;
					transition: all 0.2s;
				}
			}
		}
		input{
			width: 15px;
			height: 15px;
			margin-right: 15px;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;
		}
		&.check-all{
			border-top: solid 1px rgba($black,0.12);
			padding-top: 25px;
			.label{
				label{
					color: $text3;
					font-weight: 700;
				}
			}
		}
	}
	.date-c{
		.input{
			max-width: 166px;
			position: relative;
			.iconfont{
				position: absolute;
				right: 20px;
				top: 50%;
				transform: translate(0,-50%);
				pointer-events: none;
			}
		}
	}
	.multi-check{
		&.two-col{
			.choose{
				width: calc((100% - 38px)/2);
				&:nth-child(2n+1){
					margin-right: 15px;
				}
				@include max-md{
					width: calc((100% - 16px)/2);
				}
				@include max-sm{
					width: 100%;
					&:nth-child(2n+1){
						margin-right: 0;
					}
				}
			}
		}
		&:not(.two-col){
			.choose{
				width: calc((100% - 32px)/3);
				@include min-sm{
					&:not(:nth-child(3n)){
						margin-right: 15px;
					}
				}
				@include max-sm{
					width: calc((100% - 16px)/2);
					&:not(:nth-child(2n)){
						margin-right: 15px;
					}
				}
				@include max-xs{
					width: 100%;
					&:not(:nth-child(2n)){
						margin-right: 0;
					}
				}
			}
		}
		.multi-box{
			max-height: 260px;
			overflow-y: scroll;
			overflow-x: auto;
			display: flex;
			flex-wrap: wrap;
			@include max-md{
				// overflow: auto;
				max-height: 100%;
			}
			.mCustomScrollBox{
				width: 100%;
				position: relative;
				&:before{
					content: '';
					display: inline-block;
					width: calc(100% - 30px);
					height: 80px;
					background-image: linear-gradient(rgba($white, 0),rgba($white, 1)); 
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 1;
					pointer-events: none;
					@include max-md{
						width: 100%;
						display: none;
					}
				}
				.mCSB_container{
					display: flex;
					flex-flow: row wrap;
					justify-content: flex-start;
					@include max-md{
						overflow: auto;
						max-height: 260px;
						margin-right: 0;
					}
				}
				.mCSB_scrollTools{
					opacity: 1;
					background-color: rgba($input2,0.13);
					border-radius: 2.5px;
					right: 4px;
					width: 5px;
					@include max-md{
						display: none !important;
					}
					.mCSB_dragger{
						.mCSB_dragger_bar{
							background-color: $grey30;
							border-radius: 2.5px;
							width: 5px;
						}
						.mCSB_draggerRail{
							opacity: 0;
						}
					}
				}
			}
		}
		.choose{
			position: relative;
			margin-bottom: 15px;
			input{
				position: absolute;
				width:100%;
				height:100%;
				left: 0;
				top: 0;
				z-index: 1;
				opacity: 0;
			}
			.label{
				width: 100%;
				height: 100%;
				border-radius: 7px;
				color: $text3;
				background-color: $grey8;
				position: relative;
				cursor: pointer;
				&:before{
					content: '';
					display: inline-block;
					width: 15px;
					height: 15px;
					border: solid 1px $black;
					background-color: transparent;
					border-radius: 50%;
					position: absolute;
					left: 12px;
					top: 50%;
					transform: translate(0,-50%);
					transition: all 0.2s;
				}
				&:after{
					content: '\e91a';
					font-family: "icomoon" !important;
					display: inline-block;
					font-size: 12px;
					color: transparent;
					display: flex;
					flex-flow: row wrap;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					position: absolute;
					left: 12px;
					top: 50%;
					transform: translate(0,-50%) scale(0.4);
					transition: all 0.2s;
				}
			}
			label{
				width: 100%;
				height: 100%;
				display: flex;
				flex-flow: row nowrap;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: 0;
				font-weight: 500;
				line-height: 1.2;
				font-size: 14px;
				padding: 12px 15px 12px 40px;
				img{
					margin-right: 10px;
					border-radius: 50%;
					width: 30px;
					height: 30px;
				}
			}
			input[type="checkbox"]:checked,input[type="radio"]:checked{
				+.label{
					background-color: $text2;
					label{
						color: $white;
					}
					&:before{
						border: solid 1px $white;
						background-color: $white;
						transition: all 0.2s;
					}
					&:after{
						color: $text2;
						transition: all 0.2s;
					}
				}
			}
		}
	}
	.number-c{
		.input{
			position: relative;
			input:not([type="submit"]){
				padding-right: 30px;
			}
			a{
				position: absolute;
				right: 14px;
				opacity: 1;
				&:hover{
					opacity: 0.7;
				}
				&.plus{
					top: calc(50% - 2px);
					transform: translate(0,-100%);
				}
				&.minus{
					top: calc(50% - 6px);
					transform: translate(0,100%);
				}
			}
		}
	}
}

.form.style1{
	input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: rgba($text2,0.4);
	}
	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: rgba($text2,0.4);
		opacity: 1;
	}
	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: rgba($text2,0.4);
		opacity: 1;
	}
	input:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: rgba($text2,0.4);
	}
	textarea::-webkit-input-placeholder {
		/* WebKit browsers */
		color: rgba($text2,0.4);
	}
	textarea:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: rgba($text2,0.4);
		opacity: 1;
	}
	textarea::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: rgba($text2,0.4);
		opacity: 1;
	}
	textarea:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: rgba($text2,0.4);
	}
	input:not([type="submit"]),textarea,select{
		color: $text2;
		border: solid 1px $input;
		width: 100%;
		background-color: transparent;
		border-radius: 7px;
		font-size: 1rem;
		line-height: 165%;
		padding: 9px 25px;
		@include max-md{
			padding: 13px 25px;
		}
		// &:focus{
		// 	border: solid 1px $text;
		// }
	}
	input[type="submit"]{
		padding: 9px 45px;
		// min-width: 138px;
		margin-left: 15px;
		@include max-md{
			min-width: 1px;
			padding: 9px 25px;
			margin-top: 15px;
			margin-left: 0;
		}
	}
	.box{
		@include min-md{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
		.input{
			flex: 1;
			input,textarea{
				height: 100%;
			}
		}
	}
}

.form.style2{
	input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: $grey12;
	}
	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: $grey12;
		opacity: 1;
	}
	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: $grey12;
		opacity: 1;
	}
	input:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: $grey12;
	}
	textarea::-webkit-input-placeholder {
		/* WebKit browsers */
		color: $grey12;
	}
	textarea:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: $grey12;
		opacity: 1;
	}
	textarea::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: $grey12;
		opacity: 1;
	}
	textarea:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: $grey12;
	}
	input:not([type="submit"]),textarea,select{
		// color: $text2;
		color: $text3;
		border: solid 1px rgba($input2,0.74);
		width: 100%;
		background-color: transparent;
		border-radius: 7px;
		line-height: 165%;
		padding: 9px 15px;
		font-size: 14px;
		font-weight: 500;
		// &:focus{
		// 	border: solid 1px rgba($input2,0.74);
		// }
	}
	.error{
		input:not([type="submit"]),textarea,select{
			border: dashed 1px $red;
		}
	}
	.submit{
		margin: 35px 0 0;
		input[type="submit"]{
			min-width: 154px;
			color: $white;
			background-color: $text4;
			border: solid 1px $text4;
			padding: 2.5px 40px;
			&:hover{
				color: $text4;
				background-color: transparent;
				border: solid 1px $text4;
			}
		}
	}
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.ajax-loader {
	visibility: visible;
	width: 12px;
	height: 12px;
	background: none;
	border: 2px solid $white;
	border-radius: 50%;
	border-bottom: 2px solid rgba($white, 0);
	-webkit-animation: loading 0.5s infinite linear;
	animation: loading 0.5s infinite linear;
	margin-right: 10px;
}

.wpcf7-not-valid-tip{
	display: none;
}

.wpcf7-not-valid{
	border-bottom-color: #dc3232 !important;
}

.wpcf7 form.invalid .wpcf7-response-output, .wpcf7 form.unaccepted .wpcf7-response-output{
	border: none;
	padding: 0;
	margin-left: 0;
	margin-right: 0;
}

.wpcf7 form.sent .wpcf7-response-output{
	border: none;
	padding: 0;
	margin-left: 0;
	margin-right: 0;
}

.uploads{
	@include min-sm{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}
	.upload{
		@include min-sm{
			width: calc(50% - 10px);
		}
		@include max-sm{
			width: 100%;
			margin-top: 15px;
		}
	}
	.show-photo{
		@include max-sm{
			padding-top: 15px;
		}
	}
}
.upload{
	width: 100%;
	height: 250px;
	border-radius: 13px;
	border: 1px dashed transparent;
	background: linear-gradient($white,$white) padding-box,
	repeating-linear-gradient(-45deg,rgba($grey15,0.74) 0, rgba($grey15,0.74) 7px,$white 7px,$white 10px);
	position: relative;
	label{
		width: 100%;
		height: 100%;
		background-color: rgba($grey14,0.21);
		border-radius: 13px;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		.iconfont{
			color: $grey14;
			font-size: 46px;
			margin-bottom: 15px;
		}
		p{
			color: $grey16;
			font-size: 12px;
			line-height: 159.5%;
			margin-bottom: 0;
			text-align: center;
			strong{
				font-size: 16px;
			}
		}
	}
	input{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}
}

.show-photo{
	.img-c{
		display: inline-block;
		overflow: visible;
		max-width: calc(100% - 30px);
		margin: 0 auto;
		.iconfont{
			width: 22px;
			height: 22px;
			border-radius: 50%;
			color: $white;
			background-color: $text5;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			position: absolute;
			right: 0;
			top: 0;
			transform: translate(50%,-50%);
			cursor: pointer;
			opacity: 1;
			transition: all 0.2s;
			&:hover{
				opacity: 0.7;
				transition: all 0.2s;
			}
		}
	}
}

.payment-form-error {
	color: #fa755a;
	font-size: 14px;
}