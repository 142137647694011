.pop-up{
	display: none;
	position: fixed;
	width:100%;
	height:100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 9;
	.bg-box{
		position: absolute;
		width:100%;
		height:100%;
		left: 0;
		top: 0;
	}
	.container{
		width:100%;
		height:100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		padding: 80px 20px;
		@include max-md{
			padding: 0;
		}
	}
	.content{
		width: 100%;
		height: 100%;
		max-width: 670px;
		max-height: 465px;
		padding: 34px 43px;
		position: relative;
		z-index: 1;
		background-color: $white;
		border-radius: 17px;
		box-shadow: 0 7px 23px rgba($text7,0.2);
		@include max-md{
			max-width: 100%;
			max-height: 100%;
			border-radius: 0 !important;
			padding: 15px;
			.text-c{
				padding-right: 0;
			}
		}
	}
	.icon-close{
		width: 24px;
		height: 24px;
		font-size: 14px;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		color: $black;
		transition: all 0.2s;
		&:hover{
			cursor: pointer;
			color: $highlight;
			transition: all 0.2s;
		}
	}
	.title-box{
		h2{
			font-size: 19px;
			line-height: 117%;
			font-weight: 700;
			margin-bottom: 18px;
		}
		.iconfont{
			position: absolute;
			right: 20px;
			top: 20px;
		}
	}
	.text-c{
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100% - 40px);
		padding-right: 10px;
		h3{
			font-size: 15px;
			line-height: 187.5%;
			font-weight: 700;
		}
		p{
			font-size: 14px;
			line-height: 144%;
			font-weight: 400;
		}
	}
}

.pop-up.style3{
	.content{
		position: relative;
		padding: 30px;
		padding-right: 47px;
		.iconfont.icon-close{
			position: absolute;
			right: 22px;
			top: 22px;
			z-index: 5;
		}
	}
	.item-c{
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		.top{
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 24px;
			.img-c{
				width: 80px;
				height: 80px;
				border-radius: 9px;
				background-color: $blue2;
				display: flex;
				flex-flow: row wrap;
				justify-content: center;
				align-items: center;
				img{
					width: auto;
				}
			}
			.text-c{
				width: calc(100% - 80px);
				padding-left: 28px;
				@include max-sm{
					padding-left: 10px;
					padding-right: 0;
				}
				h2{
					font-size: 19px;
					font-weight: 700;
					color: $black;
					margin-bottom: 5px;
				}
				p{
					font-size: 12px;
					font-weight: 500;
					color: $grey13;
					margin-bottom: 9px;
				}
				.web-url{
					font-size: 14px;
					font-weight: 700;
					color: $black;
					display: flex;
					flex-flow: row wrap;
					justify-content: flex-start;
					align-items: center;
					.iconfont{
						font-size: 22px;
						margin-right: 6px;
					}
					a{
						@include reset-a;
					}
				}
			}
		}
		.description{
			margin-bottom: 22px;
			@include last-0;
			p{
				font-weight: 400;
				font-size: 14px;
				line-height: 144%;
				color: rgba($black,0.72);
			}
		}
		.bottom{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			@include max-sm{
				flex-direction: column-reverse;
			}
			.text-c{
				width: 310/593*100%;
				@include max-sm{
					width: 100%;
				}
				h3{
					margin-bottom: 5px;
				}
				p{
					
				}
			}
			.img-c{
				width: 256/593*100%;
				border-radius: 10px;
				overflow: hidden;
				@include max-sm{
					width: 100%;
					margin-bottom: 15px;
				}
				div:not([class]){
					padding-top: 171/256*100%;
				}
			}
		}
	}
}

.pop-up.style4{
	.content{
		max-width: 975px;
		max-height: 643px;
		@include max-md{
			max-height: 100%;
			max-width: 100%;
			border-radius: 0;
			padding: 40px 15px;
		}
	}
	.content-box{
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}
	.title-box{
		max-width: 420px;
		margin: 0 auto;
		h2{
			font-size: 24px;
			font-weight: 800;
			line-height: 117%;
			color: $black;
			margin-bottom: 9px;
		}
		p{
			font-size: 16px;
			font-weight: 500;
			line-height: 190%;
			color: $black;
		}
	}
	.form{
		max-width: 686px;
		margin: 30px auto 0;
		.uploads{
			max-width: 580px;
			margin: 0 auto 8px;
			.upload{
				height: 208px;
				.iconfont{
					margin-bottom: 5px;
				}
				label{
					p{
						line-height: 1;
					}
				}
			}
		}
		.box{
			>p{
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 6px;
			}
			>h2{
				font-size: 20px;
				font-weight: 700;
			}
			.textarea{
				width: 100%;
				margin-top: 25px;
				position: relative;
				display: flex;
				.icons{
					display: flex;
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(0,-50%);
					.iconfont{
						margin-right: 16px;
					}
				}
				textarea{
					width: 100%;
					height: 41px;
					border-radius: 7px;
					border: solid 1px $grey25;
					background-color: $grey26;
					color: rgba($grey26,0.32);
					font-size: 13px;
					line-height: 34px;
					font-weight: 500;
					padding: 2.5px 22px;
					padding-right: 80px;
				}
			}
		}
		.submit{
			margin-top: 40px;
			input{
				min-width: 135px;
			}
		}
	}
}

.pop-up.style5{
	.content{
		max-width: 818px;
		max-height: 610px;
		padding: 34px 48px;
		@include max-md{
			max-height: 100%;
			max-width: 100%;
			border-radius: 0;
			padding: 40px 15px;
		}
	}
	.title-box{
		h2{
			@include max-md{
				text-align: center;
			}
		}
	}
	.content-box{
		height: calc(100% - 36px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.grey-box{
		background-color: rgba($grey14,0.21);
		border-radius: 13px;
		padding: 32px 34px;
		@include max-md{
			padding: 25px 15px;
		}
		.top{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 22px;
			.left{
				h3{
					font-size: 24px;
					font-weight: 700;
					color: $text3;
					margin-bottom: 12px;
				}
				p{
					font-size: 14px;
					font-weight: 400;
					color: $text3;
					margin-bottom: 0;
					line-height: 1.2;
				}
			}
			.right{
				text-align: center;
				font-size: 16px;
				font-weight: 700;
				color: $white;
				background: $text4;
				border: solid 1px $text4;
				border-radius: 11px;
				padding: 5.5px 18px;
				margin: 5px 0;
				@include max-md{
					margin: 15px 0 0;
				}
				@include max-xs{
					width: 100%;
				}
			}
		}
		.images{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			.img-c{
				width: calc(50% - 10px);
				border-radius: 6px;
				overflow: hidden;
				@include max-sm{
					width: 100%;
					margin: 5px 0;
				}
				div{
					padding-top: 197/324*100%;
				}
			}
		}
	}
	.form.style2{
		max-width: 514px;
		margin: 14px auto;
		.box{
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
		.check-c{
			width: 46%;
			margin-top: 0;
			@include max-sm{
				width: 100%;
			}
			.label label{
				font-size: 14px;
				font-weight: 500;
				color: $text3;
			}
		}
		.prev-next{
			text-align: center;
			margin-top: 26px;
			.btn-c{
				min-width: 203px;
				padding: 3.5px 20px;
				margin: 5px;
				.iconfont{
					margin-right: 10px;
				}
			}
		}
	}
}







