body,
html {
	overflow-x: hidden !important;
	font-size: 18px;
	line-height: 34px;
	color: $text;
	font-family: 'Mulish',sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
	font-weight: 400;
	font-style: normal;
	@include max-xl{
		font-size: 16px;
		line-height: (34/18);
	}
	@include max-md{
		font-size: 14px;
	}
}

a {
	display: inline-block;
	line-height: 1.2;
	color: $primary;
	outline: none;
	text-decoration: none;
	border-bottom: solid 1px transparent;
	border-color: inherit;
	transition: all 0.2s ease-in-out;
	&:hover {
		color: $highlight;
		cursor: pointer;
		outline: none;
		text-decoration: none;
		border-bottom: solid 1px $highlight;
		transition: all 0.2s ease-in-out;
	}
	&:focus {
		outline: none;
		text-decoration: none;
		transition: all 0.2s ease-in-out;
	}
	&:visited {
		outline: none;
		text-decoration: none;
		transition: all 0.2s ease-in-out;
	}
}

p {
	color: rgba($text,0.7);
	&.subtitle{
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		color: $highlight;
		margin-bottom: 0;
		@include max-md{
			font-size: 14px;
		}
	}
	&.lead-body{
		font-size: (20rem/18);
		font-weight: 800;
		line-height: 155%;
	}
}

.h1,h1,.h2,h2,.h3,h3,.h4,h4,.h5,h5,.h6,h6 {
	color: $black;
}

// .h1,
// h1 {
// 	font-size: (80rem/18);
// 	line-height: 137%;
// 	@media (max-width:991.98px){
		
// 	}
// }

.h2,
h2 {
	font-size: (50rem/18);
	line-height: 137%;
	font-weight: 900;
	@include max-lg{
		font-size: (42rem/18);
	}
	@include max-md{
		font-size: (36rem/18);
	}
}

.h3,
h3 {
	font-size: (30rem/18);
	line-height: 109%;
	font-weight: 900;
	@include max-lg{
		font-size: (27rem/18);
	}
	@include max-md{
		font-size: (24rem/18);
	}
}

// .h4,
// h4 {
// 	font-size: (22rem/18);
// 	line-height: (32/24);
// }

// .h5,
// h5 {
// 	font-size: (20rem/18);
// 	line-height: (32/24);
// }

// .h6,
// h6 {
// 	font-size: (18rem/18);
// 	line-height: (32/24);
// }

ul{
	>li {
		position: relative;
		padding-left: (30rem/18);
		&::before {
			display: inline-block;
			content: "";
			width: (10rem/18);
			height: (10rem/18);
			border-radius: 50%;
			background-color: $highlight;
			position: absolute;
			top: (12rem/18);
			left: 0;
		}
	}
}

ol {	counter-reset: count;	>li {
		position: relative;
		padding-left: (30rem/18);		&::before {			counter-increment: count;			content: counter(count)".";			color: $highlight;			margin-right: 15px;			width: 12px;			display: inline-block;
			position: absolute;
			top: 0;
			left: 0;		}	}}

ol,
ul {
	list-style-type: none;
	li{
		margin-bottom: (34rem/18);
		@include max-md{
			margin-bottom: 5px;
		}
	}
}

b,strong{
	font-weight: bold;
}