$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;
// _variables.scss line 219
$theme-colors: (
  "white": #fff
);

// media
$zl: 1680px;
$bl: 1440px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 414px;

$f-m:            'Mulish';
$val:              35px;

$white:   	    #ffffff;
$black:         #000000;

// $text:    			#000002;
// $primary:       #000002;
$text:    			#262424;
$primary:       #262424;

$highlight:  		#31DFB5;
$highlight2:  	#57CDC1;
$highlight3:  	#40B2A6;
$highlight4:  	#2CAA9D;
$highlight5:  	#BAF1EC;
$highlight6:  	#D9F3E5;




$secondary:    	#FF766B;

$input:     		#717171;
$input2:     		#8690AE;


$grey:     	   	#F8F9FC;
$grey2:     	 	#B1B1BE;
$grey3:     	 	#4A4A4A;
$grey4:     	 	#10312E;
$grey5:     	 	#B1B1BE;
$grey6:     	 	#ADB1C1;
$grey7:     	 	#565656;
$grey8:     	 	#E6E8EE;
$grey9:     	 	#9297A9;
$grey10:     	 	#D3D5DB;
$grey11:     	 	#707075;
$grey12:     	 	#9EA5BC;
$grey13:     	 	#6B779C;
$grey14:     	 	#D9DBE2;
$grey15:     	 	#8690AE;
$grey16:     	 	#8991AD;
$grey17:     	 	#EEF0F4;
$grey18:     	 	#7B7879;
$grey19:     	 	#E8EBEF;
$grey20:     	 	#92959C;
$grey21:     	 	#363E5B;
$grey22:     	 	#F3F4F8;
$grey23:     	 	#8E8E8E;
$grey24:     	 	#7D7D7D;
$grey25:     	 	#DEDEDE;
$grey26:     	 	#FAFAFA;
$grey27:     	 	#A6A6A6;
$grey28:     	 	#A1A1A1;
$grey29:     	 	#A9A7A8;
$grey30:     	 	#707585;



$text2:    			#262424;
$text3:    			#111D36;
$text4:    			#272727;
$text5:    			#2D2D2D;
$text6:    			#414460;
$text7:     	 	#363B47;
$text8:     	 	#262F49;
$text9:     	 	#1B1B1B;


$arrow:    			#A4A4A6;
$blue:    			#4285F4;
$blue2:    			#012D6A;

$red:    			  #F44545;

$green:         #7D9B6F;


$newText:    		#262424;