.accordion {
	.item {
		border: solid 1px $grey5;
		border-radius: 17px;
		margin-bottom: 10px;
		&.is-active {
			.item-title {
				&:after {
					transform: translate(0, -50%) rotate(-180deg);
					transition: transform 0.2s;
				}
			}
		}
		.item-title {
			font-size: 17px;
			font-weight: 700;
			line-height: 187.5%;
			padding: 23px 80px 23px 36px;
			@include max-sm{
				padding: 20px 72px 20px 20px;
			}
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: all 0.2s;
			position: relative;
			cursor: pointer;
			a{
				@include reset-a;
			}
			&:hover {
				a{
					color: $highlight;
					transition: all 0.2s;
				}
			}
			&:after {
				display: block;
				content: '\e909';
				font-family: 'icomoon' !important;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				background-color: rgba($highlight,0.12);
				display: flex;
				flex-flow: row wrap;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				color: $highlight;
				position: absolute;
				right: 22px;
				top: 50%;
				transform: translate(0, -50%) rotate(0deg);
				transition: all 0.2s;
				@include max-sm{
					right: 20px;
				}
			}
		}
		.item-content {
			display: none;
			.text-c {
				margin-top: -5px;
				padding: 0 80px 36px 36px;
				@include max-md{
					padding: 0 20px 20px 20px;
				}
				p,ul li,ol li{
					font-size: 17px;
					line-height: 165%;
					color: rgba($text,0.7);
				}
				ol,
				ul {
					li{
						margin-bottom: 0;
					}
				}
				ul{
					>li {
						position: static;
						padding-left: 0;
						&::before {
							display: inline-block;
							content: "";
							width: 6px;
							height: 6px;
							border-radius: 50%;
							background-color: $highlight;
							position: static;
							margin: 0 10px 3px 0;
						}
					}
				}
			}
		}
	}
}