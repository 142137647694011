.success{
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	background-image: linear-gradient(-45deg,rgba(216, 210, 237, 1) 0%,rgba(176, 228, 193, 1) 50%,rgba(225, 247, 193, 1) 100%); 
	a:not(.btn-c){
		@include reset-a;
	}
	p{
		color: $black;
	}
	.btn-c{
		color: $white;
		background: $text4;
		border: solid 1px $text4;
		&:not(.disabled){
			&:hover{
				color: $text4;
				background-color: transparent;
				border: solid 1px $text4;
			}
		}
		&.prev{
			color: $text4;
			background: $white;
			border: solid 1px $text4;
			&:hover{
				color: $white;
				background-color: $text4;
				border: solid 1px $text4;
			}
		}
	}
	.content{
		padding: 80px 20px ;
		width: 100%;
		max-width: 656px;
	}
	.success-box{
		border-radius: 21px;
		background-color: $white;
		margin: 0 auto;
		padding: 40px 20px 52px;
		position: relative;
		>.img-c{
			position: absolute;
			top: 49%;
			left: 57%;
			transform:translate(-50%,-50%);
			width:145%;
			pointer-events: none;
		}
	}
	.text-c{
		max-width: 463px;
		margin: 0 auto;
		i{
			font-size: (42rem/18);
			display: block;
			@include min-md{
				margin-bottom: (24rem/18);
			}
		}
		h2{
			font-size: (27rem/18);
			margin-bottom: 15px;
		}
		.lead-body{
			font-weight: 700;
			font-size: (19rem/18);
			margin-bottom: (26rem/18);
		}
		p{
			font-weight: 500;
			font-size: 16px;
			line-height: 165%;
			@include max-md{
				font-size: 14px;
			}
		}
		.btn-c{
			margin: 10px 0;
		}
	}
	.small-font{
		h2{
			font-size: (24rem/18);
		}
		.form{
			max-width: 335px;
			margin: 0 auto;
			.box{
				.input{
					width: 100%;
					position: relative;
					margin-bottom: (27rem/18);
					input{
						width: 100%;
						background-color: rgba($highlight3,0.15);
						color: $highlight4;
						text-transform: uppercase;
						font-size: 16px;
						font-weight: 800;
						letter-spacing: 1.5px;
						border-radius: 13px;
						padding: 10px 50px 10px 33px;
						@include max-md{
							font-size: 14px;
						}
					}
					.iconfont{
						font-size: (22rem/18);
						color: $highlight4;
						position: absolute;
						top: 50%;
						right: 15px;
						transform:translate(0,-50%);
						cursor: pointer;
					}
				}
				.btn-c{
					margin-top: 20px;
				}
			}
		}
	}
}