.btn-c{
	text-align: center;
	font-size: 14px;
	font-weight: 800;
	line-height: inherit;
	color: $white;
	background: $primary;
	border: solid 1px $primary;
	border-radius: 7px;
	padding: 3.5px 33px;
	box-shadow: none;
	&:focus,&:visited{
		box-shadow: none;
	}
	&:not(.disabled){
		&:hover{
			box-shadow: none;
			color: $primary;
			background-color: transparent;
			border: solid 1px $primary;
		}
	}
	&.disabled{
		color: $white !important;
		background-color: rgba($text4,0.28) !important;
		border: solid 1px transparent !important;
		cursor: not-allowed !important;
	}
	&.prev{
		color: $primary;
		background: $white;
		border: solid 1px $primary;
		&:hover{
			color: $white;
			background-color: $primary;
			border: solid 1px $primary;
		}
	}
}


// .loading{
// 	opacity: 0;
// 	visibility: hidden;
// 	width: 24px;
// 	height: 24px;
// 	background: none;
// 	border: 2px solid $primary;
// 	border-radius: 50%;
// 	border-bottom: 2px solid transparent;
// 	-webkit-animation: loading .5s infinite linear;
// 	animation: loading .5s infinite linear;
// 	position: absolute;
// 	right: -50px;
// 	top: calc(50% - 12px);
// 	-webkit-transform: translate(0, -50%);
// 	-ms-transform: translate(0, -50%);
// 	transform: translate(0, -50%);
// }

// @keyframes loading {
// 	0% {
// 		transform: rotate(0deg);
// 	}
// 	100% {
// 		transform: rotate(360deg);
// 	}
// }