.breadcrumb{
	display: flex;
	display: -webkit-flex;
	-webkit-flex-flow: row wrap;
	flex-flow: row wrap;
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
	align-items: center;
	-webkit-align-items: center;
	@include reset-list;
	margin-bottom: 12px;
	background-color: transparent;
	.breadcrumb-item {
		font-size: 13px;
		@media (max-width:991.98px){
			font-size: 12px;
		}
		a{
			@include reset-a;
			color: inherit;
			&:hover{
				color: $primary;
			}
		}
		&:last-child{
			&:after{
				display: none;
			}
		}
		&:after {
			content: ">";
			display: inline-block;
			margin: 0 5px 0 8px;
			@media (max-width:991.98px){
				margin: 0 4px 0 3px;
			}
		}
	  &.active {
	    color: inherit;
	  }
	}
}
