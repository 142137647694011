.video-light-box{
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 99999;
	left: 0;
	top: 0;
	&:after{
		content: "";
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: $black;
		opacity: 0.7;
	}
	.close{
		opacity: 1;
		background: none;
		display: block;
		width: 28px;
		height: 28px;
		position: absolute;
		z-index: 9;
		right: -7px;
		top: -30px;
		transition: All 0.2s ease-in-out;
		&:hover{
			&:before{
				background: $highlight;
			}
			&:after{
				background: $highlight;
			}
		}
		&:before{
			content: "";
			display: block;
			width: 20px;
			height: 2px;
			background: $white;
			transform: rotate(-45deg) translateZ(0);
			position: absolute;
			left: 3px;
			top: 13px;
			transition: All 0.3s ease-in-out;
		}
		&:after{
			content: "";
			display: block;
			width: 20px;
			height: 2px;
			background: $white;
			transform: rotate(45deg) translateZ(0);
			position: absolute;
			left: 3px;
			top: 13px;
			transition: All 0.3s ease-in-out;
			-webkit-transition: All 0.3s ease-in-out;
			-moz-transition: All 0.3s ease-in-out;
			-o-transition: All 0.3s ease-in-out;
		}
	}
	.video-box{
		max-width: 1134px;
		width: 100%;
		position: absolute;
		z-index: 2;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 0;
		@media (max-width: 1200px){
			width: 96%;
		}
		video{
			width: 100%;
		}
		iframe{
			width: 100%;
			height: 641px;
			@media (max-width: 1200px){
				height: 52.9vw;
			}
		}
	}
}

.data-video{
    display: none;
}