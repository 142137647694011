nav {
	@include max-lg{
		position: fixed;
		z-index: 2;
		left: 0;
		top: -100vh;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.8s cubic-bezier(0.77, 0, 0.175, 1), top 0.8s cubic-bezier(0.77, 0, 0.175, 1);
		-webkit-transition: opacity 0.8s cubic-bezier(0.77, 0, 0.175, 1), top 0.8s cubic-bezier(0.77, 0, 0.175, 1);
		-moz-transition: opacity 0.8s cubic-bezier(0.77, 0, 0.175, 1), top 0.8s cubic-bezier(0.77, 0, 0.175, 1);
		-o-transition: opacity 0.8s cubic-bezier(0.77, 0, 0.175, 1), top 0.8s cubic-bezier(0.77, 0, 0.175, 1);
		background-color: $white;
	}
	&.open {
		top: 0;
		opacity: 1;
	}
	.nav-c {
		@include min-lg{
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;
			align-items: center;
		}
		@include max-lg{
			width: 100%;
			padding: 100px 0 0;
			max-height: calc(100% - 80px);
			overflow: auto;
			text-align: center;
		}
		>.btn-c{
			min-width: 138px;
			@include min-lg{
				margin-left: 14px;
			}
			@include max-lg{
				// margin-left: 35px;
				margin-top: 30px;
			}
			// @include max-md{
			// 	margin-left: 20px;
			// }
		}
		> ul {
			@include reset-list;
			@include min-lg{
				display: flex;
			}
			@include max-lg{
				display: inline-block;
				width: 100%;
			}
			> li {
				@include min-lg{
					margin-right: 79px;
				}
				@include max-lg{
					padding: 13px 35px;
				}
				@include max-md{
					padding: 13px 20px;
				}
				>a {
					font-size: 16px;
					font-weight: bold;
					color: rgba($text2,0.8);
					@include reset-a;
					&:hover{
						color: $highlight;
					}
					@include min-lg{
					}
					@include max-lg{
						
					}
				}
				&.menu-item-has-children {
					position: relative;
					&:hover{
						@include min-lg{
							.sub-menu{
								opacity: 1;
								visibility: visible;
								transition: all 0.2s;
							}
						}
					}
					i.icon-menu-arrow {
						display: inline-block;
						font-family: 'icomoon';
						font-size: 12px;
						transition: all 0.2s;
						position: absolute;
						right: 0;
						top: 16px;
						color: $white;
						transform: translate(0%, -50%) rotate(0deg) scale(0.6);
					}
					&.show {
						@include max-lg{
							transition: background-color 0.2s;
							> a {
								&:hover {
									background-color: transparent;
								}
							}
							> i.icon-arrow {
								transform: translate(0%, -50%) rotate(180deg) scale(0.6);
								transition: all 0.2s;
							}
						}
					}
					> .sub-menu {
						@include reset-list;
						@include min-lg{
							display: none !important;
							opacity: 0;
							visibility: hidden;
							transition: all 0.2s;
							position: absolute;
							left: 0;
							top: 100%;
							width: 195px;
							padding: 0 24px;
						}
						@include max-lg{
							padding: 13px 0;
							margin: 35px -35px 0;
							display: none;
						}
						> li {
							@include min-lg{
								padding: 10px 0;
								border-bottom: solid 2px $white;
								&:last-child{
									border-bottom: none;
								}
							}
							> a {
								font-size: 15px;
								@include reset-a;
								@include min-lg{
								}
								@include max-lg{
									line-height: 2.93;
									letter-spacing: 0.5px;
									color: rgba($white,0.6);
									width: 100%;
									padding: 0 35px;
								}
								&:hover {
									@include min-lg{
										color: $highlight;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}


