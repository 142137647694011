.container {
	max-width: 100%;
	padding: 0 115px;
	@include until(1441px){
		max-width: 1310px;
		padding: 0 50px;
	}
	@include until(1410px){
		max-width: 100%;
	}
	@include max-lg{
		padding: 0 35px;
	}
	@include max-md{
		padding: 0 20px;
	}
}

.container-fluid {
	@include make-container(64px);
	@include max-md{
		@include make-container(40px);
	}
}

div{
	.container,.container-fluid{
		.content{
			border-radius: 35px;
			overflow: hidden;
		}
	}
}

.bg-grey{
	background-color: $grey;
}