.guide{
	.content{
		padding: 80px 0;
		@include max-md{
			padding: 80px 0 45px;
		}
	}
	.text-c{
		max-width: 620px;
		margin: 0 auto;
		h2{
			margin-bottom: 6px;
		}
	}
	&.inner-banner{
		.content{
			padding: 80px 20px;
			@include max-md{
				padding: 80px 20px 45px;
			}
		}
		+.timeline{
			margin-top: 80px;
		}
	}
}

.faq-guide{
	.content{
		padding: 80px 20px;
		@include max-md{
			padding: 80px 20px 45px;
		}
	}
	.text-c{
		max-width: 765px;
		margin: 0 auto;
		h2{
			margin-bottom: 25px;
		}
		.form{
			margin-bottom: 22px;
			.box{
				@include md-sm{
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					align-items: center;
					input[type="submit"]{
						margin-left: 15px;
						margin-top: 0;
					}
				}
			}
			input:not([type="submit"]){
				background-color: $white;
			}
			input[type="submit"]{
				@include min-md{
					padding: 9px 40px;
				}
				@include max-sm{
					width: 100%;
				}
			}
		}
	}
	.group{
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		max-width: 1170px;
		margin: 74px auto 0;
		.item-c{
			width: 25%;
			padding: 0 13px;
			@include max-lg{
				width: 50%;
				margin-bottom: 26px;
			}
			@include max-sm{
				width: 100%;
				max-width: 292px;
				margin: 0 auto 25px;
				padding: 0;
			}
			// &:hover,&.is-active{
			&:hover{
				a{
					color: $text;
					border: solid 2px $highlight !important;
					box-shadow: 0 12px 44px -8px rgba($grey4,0.3);
					transition: all 0.2s;
					@include min-lg{
						transform: translate(0,-10px);
					}
					.iconfont{
						color: $highlight;
						transition: all 0.2s;
					}
					h3{
					}
				}
			}
			a{
				width: 100%;
				height: 100%;
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				@include reset-a;
				background-color: $white;
				border-radius: 25px;
				border: solid 2px transparent;
				box-shadow: 0 7px 34px -7px rgba($grey3,0.15);
				padding: 35px 15px 37px;
				transform: translate(0,0);
				transition: all 0.2s;
				.iconfont{
					font-size: 48px;
					color: $grey2;
					transition: all 0.2s;
					margin-bottom: 18px;
				}
				h3{
					font-size: 18px;
					font-weight: 800;
					line-height: 1.2;
					margin-bottom: 0;
				}
			}
		}
	}
}
.faq-list{
	margin-top: 70px;
	margin-bottom: 40px;
	@include max-md{
		margin-top: 50px;
		margin-bottom: 0;
	}
	.content{
		max-width: 864px;
		margin: 0 auto;
	}
	.item-c{
		margin-bottom: 72px;
		@include max-md{
			margin-bottom: 50px;
		}
		h3{
			text-align: center;
			margin-bottom: 37px;
		}
	}
}
.timeline{
	@include min-md{
		position: relative;
		&:before{
			content: '';
			display: inline-block;
			width: 2px;
			height: 100%;
			background-image: linear-gradient(rgba($highlight, 1) 92.8%, rgba($highlight, 0)); 
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%,0);
		}
	}
	.item-c{
		overflow: hidden;
		@include min-md{
			display: flex;
			justify-content: space-between;
			align-items: center;
			&:first-child{
				.text-c{
					&:before{
						content: '';
						display: inline-block;
						width: 5px;
						height: 100vh;
						background-color: $white;
						position: absolute;
						left: 0;
						top: 69px;
						transform: translate(-50%,-100%);
					}
				}
			}
			&:nth-child(2n+1){
				flex-flow: row-reverse wrap;
				.text-c{
					padding-left: 5%;
					@include max-lg{
						padding-left: 50px;
					}
					.iconfont{
						left: 0;
						transform: translate(-50%,0);
					}
				}
				.img-c{
					@include max-lg{
						padding-right: 50px;
					}
				}
			}
			&:nth-child(2n){
				flex-flow: row wrap;
				.text-c{
					text-align: right;
					padding-right: 5%;
					@include max-lg{
						padding-right: 50px;
					}
					.iconfont{
						right: 0;
						transform: translate(50%,0);
					}
				}
				.img-c{
					@include max-lg{
						padding-left: 50px;
					}
				}
			}
			&:nth-child(4n+1){
				.text-c h3{
					margin-bottom: 8px;
				}
			}
			&:nth-child(4n+2){
				.text-c h3{
					margin-bottom: 16px;
				}
			}
			&:nth-child(4n+3){
				.text-c h3{
					margin-bottom: 20px;
				}
			}
			&:nth-child(4n){
				.text-c h3{
					margin-bottom: 20px;
				}
			}
		}
		@include max-md{
			margin-bottom: 20px;
		}
		.img-c{
			width: 45%;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			align-items: center;
			@include max-lg{
				width: 50%;
			}
			@include max-md{
				width: auto;
				justify-content: center;
			}
			img{
				margin: 0 auto;
			}
		}
		.text-c{
			width: 50%;
			position: relative;
			// margin-bottom: 7%;
			@include max-md{
				width: 100%;
				text-align: center;
				margin-bottom: 15px;
				p{
					br{
						display: none;
					}
				}
			}
			.iconfont{
				font-size: 32px;
				width: 69px;
				height: 69px;
				border-radius: 50%;
				background-color: $highlight;
				color: $white;
				display: flex;
				flex-flow: row wrap;
				justify-content: center;
				align-items: center;
				@include min-md{
					position: absolute;
					top: 5px;
				}
				@include max-md{
					margin: 0 auto 10px;
				}
			}
			p{
				line-height: 1.7;
			}
		}
	}
}

.end-weight{
	.content{
	}
	.title-box{
		text-align: center;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: flex-start;
		padding: 34px 0;
		h2{
			display: block;
			max-width: 353px;
			margin: 0 25px;
			font-size: 31px;
			line-height: 118%;
		}
		.img-c{
			transform: translate(0,53%);
			@include max-lg{
				display: none;
			}
			img{
				max-width: 165px;
			}
		}
	}
	.group{
		padding: 40px 0;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		.item-c{
			border-radius: 30px;
			overflow: hidden;
			width: calc(50% - 20px);
			display: flex;
			flex-flow: column wrap;
			justify-content: space-between;
			align-items: flex-end;
			@include max-md{
				width: 100%;
				+.item-c{
					margin-top: 15px;
				}
			}
			&.win{
				background-color: rgba($highlight2,0.2);
			}
			&.fail{
				background-color: rgba($secondary,0.2);
			}
			.text-c{
				width: 100%;
				padding: (68rem/18) (74rem/18) 20px;
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: center;
				@include max-lg{
					padding: 35px 35px 20px;
				}
				.box{
					flex: 1;
					max-width: 315px;
					padding-right: 15px;
					p{
						font-size: (20rem/18);
						line-height: 165%;
					}
				}
				img{
					max-width: (84rem/18);
					@include max-xs{
						max-width: 50px;
					}
				}
			}
			.img-c{
				width: 506/668*100%;
				max-width: 506px;
			}
		}
	}
}

.features{
	.content{
		padding: 68px 0 87px;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		>p{
			width: 100%;
			margin-bottom: 6px;
		}
		>.text-c{
			@include min-md{
				width: 29%;
			}
			h2{
				line-height: 111.5%;
				margin-bottom: 24px;
			}
		}
		>.group{
			@include min-md{
				width: 55%;
			}
			@include last-0;
			@include max-md{
				margin-top: 30px;
			}
			.item-c{
				&:nth-child(3n+1){
					.img-c{
						background-color: #EFF8F6;
					}
				}
				&:nth-child(3n+2){
					.img-c{
						background-color: rgba(#FF9FD4,0.18);
					}
				}
				&:nth-child(3n){
					.img-c{
						background-color: rgba(#0EAFFF,0.2);
					}
				}
			}
			.item-c{
				margin-bottom: 52px;
				@include min-xs{
					display: flex;
				}
				.img-c{
					width: 96px;
					height: 96px;
					border-radius: 21px;
					display: flex;
					flex-flow: row wrap;
					justify-content: center;
					align-items: center;
					@include max-xs{
						margin-bottom: 15px;
					}
				}
				.text-c{
					flex: 1;
					@include min-xs{
						padding: 0 25px 0 35px;
					}
					h3{
						font-size: 24px;
						line-height: 1;
						font-weight: 800;
						margin-bottom: 18px;
					}
					p{
						line-height: 165%;
					}
				}
			}
		}
	}
}

.comments{
	.slider{
		@include max-md{
			padding-bottom: 60px;
		}
		.slick-arrow{
			width: 57px;
			height: 57px;
			border-radius: 50%;
			font-size: 12px;
			color: $arrow;
			border: solid 2px transparent;
			position: absolute;
			bottom: 17%;
			right: 7.5%;
			z-index: 1;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			transition: all 0.2s;
			@include until(1300px){
				bottom: 10px;
				right: 10px;
			}
			@include max-md{
				width: 48px;
				height: 48px;
				right: 50%;
				bottom: 10px;
			}
			&:hover{
				color: $text;
				border: solid 2px $text;
				transition: all 0.2s;
			}
			&.slick-prev{
				transform: translate(calc(-20px - 100%),0);
				@include max-md{
					transform: translate(-10px,0);
				}
			}
			&.slick-next{
				color: $text;
				border: solid 2px $text;
				transform: rotateY(180deg);
				@include max-md{
					transform: rotateY(180deg) translate(calc(-100% - 10px),0);
				}
				&:hover{
					color: $highlight;
					border: solid 2px $highlight;
					transition: all 0.2s;
				}
			}
		}
		.slick-c{
			padding: 45px;
			@include min-lg{
				display: flex !important;
				flex-flow: row wrap;
				justify-content: space-between;
				align-items: center;
			}
			@include max-md{
				padding: 20px;
			}
			.img-c{
				border-radius: 21px;
				overflow: hidden;
				@include min-lg{
					width: 617/1286*100%;
				}
				@include max-lg{
					margin-bottom: 15px;
				}
				div:not([class]){
					padding-top: 403/617*100%;
				}
			}
			.text-c{
				@include min-lg{
					width: 100% - 617/1286*100%;
					padding: 0 5% 0 8%;
				}
				blockquote{
					margin-bottom: 24px;
				}
				.cite{
					display: flex;
					align-items: center;
					padding-right: 140px;
					@include until(1300px){
						padding-right: 110px;
					}
					@include min-lg{
						margin-left: -10px;
					}
					@include max-md{
						padding-right: 0;
					}
					.img-c{
						overflow: visible;
						width: 70px;
						margin-right: 20px;
						border-radius: 50%;
						border: solid 4px $white;
						box-shadow: 0 7px 23px rgba($grey3,0.29);
						margin-bottom: 0;
						div:not([class]){
							border-radius: 50%;
							overflow: hidden;
							padding-top: 1*100%;
						}
					}
					cite{
						font-style: normal;
						p.name{
							font-weight: 800;
							font-size: (20rem/18);
							line-height: 187.5%;
							margin-bottom: 0;
							@include max-md{
								line-height: 1.2;
							}
						}
						p.years{
							font-size: (16rem/18);
							line-height: 1.3;
							margin-bottom: 0;
							color: rgba($text,0.4);
						}
					}
				}
			}
		}
	}
}

.single-editor{
	.content{
		padding: 55px 0;
		.text-c{
			max-width: 679px;
			margin: 0 auto;
		}
		.images{
			margin: 3rem 0 2.1rem;
			@include max-md{
				margin: 2rem 0;
			}
			@include min-sm{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
			img{
				border-radius: 25px;
				overflow: hidden;
				width: 100%;
				@include max-sm{
					width: 100% !important;
					+img{
						margin-top: 20px;
					}
				}
			}
		}
		h2{
			font-family: rubik, sans-serif;
			font-weight: 700;
			font-style: normal;
			font-size: 40px;
			line-height: 106.7%;
			margin-bottom: 1rem;
		}
		@include min-md{
			ul,ol,p,h3{
				margin-bottom: 1.5rem;
			}
			ul,ol{
				margin-top: 2.3rem;
				+h3{
					margin-top: 4rem;
				}
			}
		}
		@include max-md{
			.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6,ul,ol{
				margin: 1rem 0;
			}
		}
	}
}

.subscribe{
	.content{
		padding: (107rem/18) 20px;
		background-image: linear-gradient(-45deg,rgba(225, 247, 193, 1) 0,rgba(176, 228, 193, 1) 50%,rgba(216, 210, 237, 1) 100%); 
		@include max-md{
			padding: 50px 20px;
		}
	}
	.text-c{
		max-width: 650px;
		margin: 0 auto;
		h2{
			line-height: 111%;
		}
	}
	.form{
		max-width: 542px;
		margin: 38px auto 10px !important;
		text-align: left;
		p{
			font-weight: 800;
			font-style: normal;
			margin-bottom: 3px;
		}
		input:not([type="submit"]){
			background-color: $white;
			border: solid 1px $grey27;
		}
		input:not([type="submit"])::-webkit-input-placeholder {
			/* WebKit browsers */
			color: $grey28;
		}
		input:not([type="submit"]):-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			color: $grey28;
			opacity: 1;
		}
		input:not([type="submit"])::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: $grey28;
			opacity: 1;
		}
		input:not([type="submit"]):-ms-input-placeholder {
			/* Internet Explorer 10+ */
			color: $grey28;
		}
		input[type="submit"]{
			@include max-sm{
				width: 100%;
			}
		}
	}
}

