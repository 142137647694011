.gallery {
	max-width: 568px;
	margin: 0 auto 98px;
	position: relative;
	ul {
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		justify-content: flex-start;
		-webkit-justify-content: flex-start;
		@include reset-list;
		margin: 0 -6px;
		li {
			list-style: none;
			width: 25%;
			padding: 6px;
			@media (max-width:575.98px){
				width: 50%;
			}
			a {
				position: relative;
				display: block;
				padding-top: 100%;
				border-bottom: none;
				@include reset-bg;
				&:before {
					content: '';
					display: inline-block;
					width: 100%;
					height: 100%;
					background-color: transparent;
					position: absolute;
					top: 0;
					left: 0;
					transition: all 0.2s;
				}
				&:after {
					content: '\e816';
					display: inline-block;
					font-family: 'iconfont' !important;
					font-size: 24px;
					color: $white;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					transition: all 0.2s;
					opacity: 0;
				}
				&:hover {
					&:before {
						transition: all 0.2s;
					}
					&:after {
						opacity: 1;
						transition: all 0.2s;
					}
				}
			}
		}
	}
	.btn {
		position: absolute;
		bottom: 6px;
		left: 50%;
		transform: translate(-50%,50%);
		z-index: 1;
		padding: 12px 23px;
	}
}